import {ArrowUpIcon, BanIcon, FireIcon, XCircleIcon} from "@heroicons/react/solid";
import Button from "../../atoms/Button";
import MeasureRequestButton from "../MeasureRequestButton";

type props = {
    measure: any
    category?: any
    showButton?: boolean
    text: string
}

export default function UnavailableMeasureCard({measure, category, showButton = false, text}: props): React.ReactElement {
    return (
        <div className={`col-span-1 h-full relative p-6  bg-white cursor-not-allowed rounded-lg shadow ${showButton ? '' : 'opacity-[0.7]'}`}>
            <div className="flex justify-between flex-col h-full">
                <div>
                                  <span
                                      className="inline-flex items-center mb-6 px-2.5 py-0.5 bg-red-100 text-red-800 rounded-md text-sm font-medium">
                                      <BanIcon className="w-4 h-4 mr-1"/>
                                      {text}</span>
                    <div className="flex items-center">
                    <span className="mr-3 flex-none rounded-lg inline-flex p-1 bg-blue-100 text-teal-700 ring-2 ring-white">
                        <img alt={measure.name} className="h-8 w-8 object-cover" src={measure.image_url}/>
                    </span>
                        <div>
                            <h4 className="text-xxl font-bold tracking-tight text-gray-900 md:text-2xl leading-tight md:leading-tight">{measure.name}</h4>
                        </div>
                    </div>
                </div>

                <div className="blur-sm select-none">
                    <div className="grid grid-cols-1 gap-4 mb-6 sm:grid-cols-2">
                        <div>
                            <span className="text-sm block font-medium text-gray-500 truncate">Energiebesparing</span>
                            <div
                                className="inline-flex items-baseline px-2.5 py-0.5 rounded-full text-md font-medium bg-green-100 text-green-800 md:mt-1">

                                <ArrowUpIcon className="-ml-1 mr-0.5 flex-shrink-0 self-center h-5 w-5 text-green-500"/>
                                <span className="sr-only">
                Rendement
              </span>
                                20%
                            </div>
                        </div>
                        <div>
                            <span className="text-sm block font-medium text-gray-500 truncate">Besparing per jaar</span>
                            <span className="text-xl mt-1 block font-semibold text-gray-900">€100</span>
                        </div>
                    </div>
                    {!showButton && <Button to="/" color="blue" text="Vrijblijvende offerte aanvragen"/> }
                </div>
                {showButton && <MeasureRequestButton disabled color="blue" category={category} measure={measure}/> }
            </div>

        </div>
    )
}
