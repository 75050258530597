import {Link} from "react-router-dom";
import Data from "../../../utils/Data";
import {BuildingMapper} from "../../../mappers/Building";
import toast from "react-hot-toast";
import Loader from "../../atoms/Loader";
import React from "react";
import Button from "../../atoms/Button";


type props = {
    measure: any
}

export default function ServiceHouseFields({measure} : props): React.ReactElement {
    const {data, isLoading, isError} = Data('/me/building')
    const {data: metaData, isLoading: metaisLoading} = Data('/measures/'+measure.slug+'/meta')

    const building = BuildingMapper(data)

    if (isError) toast.error("Kan 'mijn woning' niet laden")

    return (
        <div className="bg-white shadow sm:rounded-lg mb-8">

                <div className="px-4 py-5 sm:px-6">
                    <h2 className="text-lg leading-6 font-medium text-gray-900">
                        Woninggegevens
                    </h2>
                </div>
                <div className="border-t border-gray-200 px-4 pt-3 pb-5 sm:px-6">
                    {isLoading && metaisLoading ? <Loader/> :
                        <>
                            <div className="sm:grid sm:grid-cols-3 border-b py-3 border-gray-200">
                                <dt className="text-base font-medium text-gray-500 sm:col-span-2">Woonoppervlakte</dt>
                                <dd className="mt-1 text-base text-gray-900 sm:mt-0 sm:col-span-1">{building.surface}m2</dd>
                            </div>
                            <div className="sm:grid sm:grid-cols-3 border-b py-3 border-gray-200">
                                <dt className="text-base font-medium text-gray-500 sm:col-span-2">Bouwjaar</dt>
                                <dd className="mt-1 text-base text-gray-900 sm:mt-0 sm:col-span-1">{building.year}</dd>
                            </div>
                            {metaData &&
                                <>
                                    {metaData?.map((field: any) =>
                                        <div key={field.label} className="sm:grid sm:grid-cols-3 border-b py-3 border-gray-200">
                                            <dt className="text-base font-medium text-gray-500 sm:col-span-2">{field.label}</dt>
                                            {field.unit === 'zonnepanelen' ?
                                                (<dd className="mt-1 text-base text-gray-900 sm:mt-0 sm:col-span-1">{field.value}</dd>) :
                                                (<dd className="mt-1 text-base text-gray-900 sm:mt-0 sm:col-span-1">{field.value}{field.unit}</dd>)}
                                        </div>
                                    )}
                                </>
                            }
                            <p className="my-4 text-sm text-gray-500">De getoonde besparingen worden berekend op basis van deze
                                gegevens.</p>
                            <div className="mt-8">
                                <Button text="Woninggegevens wijzigen" to="/mijn-woning#meta" color="gray"/>
                            </div>
                        </>
                    }
                </div>

            </div>
    )
}
