import {ConvertBuildingType} from "../utils/ConvertBuildingType";
import {BuildingType} from "../types/BuildingType";

export function BuildingMapper(data: any): BuildingType {
    return {
        ...data,
        type: ConvertBuildingType(data?.type),
        rawType: data?.type
    }
}

