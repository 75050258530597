import Breadcrumbs from "../../components/organisms/Breadcrumbs"
import {
    HomeIcon, LightningBoltIcon, PuzzleIcon,
} from '@heroicons/react/outline'
import React, {useState} from "react";
import Loader from "../../components/atoms/Loader";
import Data from "../../utils/Data";
import toast from "react-hot-toast";
import {BuildingMapper} from "../../mappers/Building";
import GeneralBuildingForm from "../../components/molecules/Forms/Building/GeneralBuildingForm";
import EnergyBuildingForm from "../../components/molecules/Forms/Building/EnergyBuildingForm";
import axios from "../../lib/axios";
import MetaForm from "../../components/molecules/Forms/Building/MetaForm";
import OwnershipForm from "../../components/molecules/Forms/Building/OwnershipForm";
import { UserIcon} from "@heroicons/react/solid";
import {ExclamationCircleIcon} from "@heroicons/react/solid";


export default function MyBuilding(): React.ReactElement {
    const {data, isLoading, isError } =  Data('/me/building')
    let building = BuildingMapper(data)

    if (isError) toast.error("Kan 'mijn woning' niet laden")

    const links = [
        { name: 'Mijn woning', to: '/mijn-woning' },
    ]

    function energyUsage() {
        if(building.gas_usage || building.electricity_usage) return true;
        return false;
    }

    function classNames(...classes: any) {
        return classes.filter(Boolean).join(' ')
    }

    const navigation = [
        { name: 'Algemene gegevens', href: '#algemeen', icon: HomeIcon, current: false, action: false },
        { name: 'Energieverbruik', href: '#energieverbruik', icon: LightningBoltIcon, current: false, action: true },
        { name: 'Eigendomsinformatie', href: '#eigendomsinformatie', icon: UserIcon, current: false, action: false },
        { name: 'Maatregel specifiek', href: '#meta', icon: PuzzleIcon, current: false, action: false },
    ]

    window.onscroll = function() {myFunction()};

    function myFunction() {
        const winScroll = document.body.scrollTop || document.documentElement.scrollTop;
        const height = document.documentElement.scrollHeight - document.documentElement.clientHeight;
        const scrolled = (winScroll / height) * 100;
        // @ts-ignore
        document.getElementById("myBar").style.width = scrolled + "%";
    }

    return (
        <div className="max-w-5xl px-4 container mx-auto">
            <Breadcrumbs links={links}/>

            <h1 className="text-2xl font-semibold text-gray-900 mb-8">Mijn woning</h1>

            <div className="mb-8 sticky top-0 pt-4 -ml-4 -mr-4 px-4 bg-gray-100 z-40">
                <div className="sm:hidden">
                    <label htmlFor="tabs" className="sr-only">
                        Kies een categorie
                    </label>
                    <select
                        id="tabs"
                        name="tabs"
                        className="block w-full rounded-b-none focus:ring-blue-500 focus:border-blue-500 border-gray-300 rounded-md"
                    >
                        {navigation.map((tab) => (
                            <option key={tab.name}>{tab.name}</option>
                        ))}
                    </select>
                </div>
                <div className="hidden sm:block">
                    <nav className="relative z-0 rounded-lg rounded-b-none shadow flex divide-x divide-gray-200" aria-label="Tabs">
                        {navigation.map((tab, tabIdx) => (
                            <a
                                key={tab.name}
                                href={tab.href}
                                className={classNames(
                                    tab.current ? 'text-gray-900' : 'text-gray-500 hover:text-gray-700',
                                    tabIdx === 0 ? 'rounded-l-lg' : '',
                                    tabIdx === navigation.length - 1 ? 'rounded-r-lg' : '',
                                    'group relative min-w-0 flex-1 rounded-b-none overflow-hidden bg-white py-4 px-4 text-sm font-medium text-center hover:bg-gray-50 focus:z-10'
                                )}
                                aria-current={tab.current ? 'page' : undefined}
                            >
                                <tab.icon
                                    className={classNames(
                                        tab.current
                                            ? 'text-blue-500 group-hover:text-blue-500'
                                            : 'text-gray-400 group-hover:text-gray-500',
                                        'flex-shrink-0 mr-2  h-5 w-5 inline-block'
                                    )}
                                    aria-hidden="true"
                                />
                                <span>{tab.name}
                                    {(tab.name === 'Energieverbruik' && !energyUsage()) && <ExclamationCircleIcon className="inline-block text-yellow-300  w-5 h-5 ml-2 "/>}
                                </span>
                                <span
                                    aria-hidden="true"
                                    className={classNames(
                                        tab.current ? 'bg-blue-500' : 'bg-transparent',
                                        'absolute inset-x-0 bottom-0 h-0.5'
                                    )}
                                />
                            </a>
                        ))}
                    </nav>
                </div>
                <div className="w-full h-1 bg-gray-100 rounded-b">
                    <div className="w-0 h-1 bg-blue-500 rounded-b" id="myBar"></div>
                </div>
            </div>

                    {isLoading ? <Loader/> :
                        <div>
                            <GeneralBuildingForm building={building}/>
                            <EnergyBuildingForm building={building}/>
                            <OwnershipForm building={building}/>
                            <MetaForm/>
                        </div>
                    }
                </div>

    )
}
