import TextInput from "../../../atoms/Input/TextInput";
import Loader from "../../../atoms/Loader";
import Button from "../../../atoms/Button";
import React, {useState} from "react";
import {BuildingType} from "../../../../types/BuildingType";
import UpdateData from "../../../../utils/UpdateData";
import SelectInput from "../../../atoms/Input/SelectInput";
import axios from "../../../../lib/axios";
import toast from "react-hot-toast";

type props = {
    building: BuildingType
    title?: string
    description?: string
    onboarding?: boolean
    nextStep?: any
}

export default function GeneralBuildingForm({building, nextStep, title = 'Algemene gegevens', description = 'Dit zijn de algemene gegevens over de woning zoals het adres.', onboarding } : props ): React.ReactElement {
    const [updateLoading, setUpdateLoading]= useState(false)
    const [surface, setSurface]= useState(building.surface)
    const [year, setYear]= useState(building.year)
    const [buildingType, setBuildingType]= useState(building.rawType)

    let buildingTypes = [
        {value: 'DETACHED_HOUSE', label: 'Vrijstaande woning'},
        {value: 'SEMI_DETACHED_HOUSE', label: 'Geschakelde woning / 2 onder 1 kap'},
        {value: 'TERRACED_HOUSE', label: 'Tussenwoning'},
        {value: 'CORNER_HOUSE', label: 'Hoekwoning'},
        {value: 'APARTMENT', label: 'Appartement'},
        {value: 'BUNGALOW', label: 'Bungalow'},
        {value: 'GARAGE', label: 'Garage'},
        {value: 'OTHER', label: 'Anders'}
    ]

    const update = (state: boolean) => {
        setUpdateLoading(state)
        if(state) {
            if(nextStep) nextStep();
        }
    }

    const updateGeneralBuilding = () => {
        UpdateData('/me/building', {year, surface, type: buildingType}, 'Woning is bijgewerkt', 'Kan woning niet bijwerken', update)
    }

    function fillBuildingData() {
        axios.post('/me/building/fetch-bag')
            .then(function (response) {
                setSurface(response.data.data.surface)
                setYear(response.data.data.year)
                toast.success('Woninggegevens zijn opgehaald uit Kadaster')
            })
            .catch(function (error) {
                if(error.response.data.message) {
                    toast.error(error.response.data.message)
                } else {
                    toast.error('Kan woninggegevens niet ophalen uit Kadaster')
                }
            });
    }

    return (
        <form action="#" id="algemeen" method="POST" className={`${onboarding ? '' : 'mb-12'}`}>
            <div className={`${onboarding ? '' : 'shadow sm:rounded-md'}`}>
                <div className={`${onboarding ? '' : 'bg-white py-6 px-4 space-y-6 sm:p-6'}`}>
                    {!onboarding &&
                        <div>
                        <h3 className="text-lg leading-6 font-medium text-gray-900">{title}</h3>
                        <p className="mt-1 text-sm text-gray-500">{description}</p>
                        </div>
                    }

                    <div className="grid grid-cols-6 gap-6">
                        {!onboarding &&
                            <>
                                <div className="col-span-6 sm:col-span-2">
                                    <TextInput label="Postcode" readOnly defaultValue={building.zipcode} type="text" required reference="zipcode"/>
                                </div>

                                <div className="col-span-6 sm:col-span-2">
                                    <TextInput label="Huisnummer" readOnly defaultValue={building.number} type="text" required reference="number"/>
                                </div>
                                <div className="col-span-6 sm:col-span-2">
                                    <TextInput label="Toevoeging" readOnly defaultValue={building.addition} type="text" required reference="addition"/>
                                </div>

                                <div className="col-span-12 sm:col-span-3">
                                    <TextInput label="Straat" readOnly defaultValue={building.street} type="text" required reference="street"/>
                                </div>
                                <div className="col-span-12 sm:col-span-3">
                                    <TextInput label="Woonplaats" readOnly defaultValue={building.city} type="text" required reference="city"/>
                                </div>
                            </>
                        }
                        <div className="col-span-12 mt-4 sm:col-span-6">
                            <SelectInput onChange={(e: any) => {
                                setBuildingType(e.target.value);
                            }} label="Woning type" tooltip="Indien je dit niet (zeker) weet vul dan 'anders' in" defaultValue={building.rawType} required
                                       reference="type" options={buildingTypes}/>
                        </div>
                        {!onboarding &&
                            <>
                                <div className="col-span-12 mt-4 sm:col-span-3">
                                    <TextInput onChange={(e: any) => {
                                        setSurface(e.target.value);
                                    }} label="Oppervlakte in m2" value={surface} type="number" required
                                               reference="surface"/>
                                </div>
                                <div className="col-span-12 mt-4 sm:col-span-3">
                                    <TextInput onChange={(e: any) => {
                                        setYear(e.target.value);
                                    }} label="Bouwjaar" type="number" value={year} required reference="year"/>
                                </div>
                            </>
                        }
                    </div>
                </div>
                <div className={`${onboarding ? 'flex justify-end mt-6' : 'px-4 py-3 bg-gray-50 flex justify-between items-center text-right sm:px-6'}`}>
                    <div>
                        {!onboarding &&
                            <Button onClick={() => fillBuildingData()} text="Woninggegevens
                                ophalen uit Kadaster" color="gray"/>
                        }
                    </div>
                    <div className="flex items-center">
                        {updateLoading && <Loader inline size="small"/> }
                        <div className="ml-2">
                            <Button onClick={() => updateGeneralBuilding()} color="green" text="Opslaan"/>
                        </div>
                    </div>

                </div>
            </div>
        </form>
    )
}

