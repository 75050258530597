import Data from "../../../utils/Data";
import Loader from "../../atoms/Loader";
import toast from "react-hot-toast";
import {AccountMapper} from "../../../mappers/Account";
import {UserIcon} from "@heroicons/react/solid";
import React from "react";
import Onboarding from "../Onboarding";

type props = {
    content?: string
    welcome?: string
}

export default function WelcomeCard({content, welcome}: props): React.ReactElement {
    const {data, isLoading, isError } =  Data('/me')

    const account = AccountMapper(data)

    if (isError) toast.error("Kan 'welkomsbericht' niet laden")

    const greeting = () => {
        if(welcome) return welcome;
        const hours = new Date().getHours();
        let greet = 'Hallo'
        if (hours < 12)
            greet = 'Goedemorgen';
        else if (hours >= 12 && hours <= 17)
            greet = 'Goedemiddag';
        else if (hours >= 17 && hours <= 24)
            greet = 'Goedenavond';
        return greet;
    }

    return (
        <div className="col-span-1 p-8 bg-white rounded-lg shadow">
            {isLoading ? <Loader/> :
                <div>
                    <div className="flex items-center mb-6">
                        <span
                            className="mr-4 rounded-lg inline-flex p-3 bg-blue-100 text-teal-700 ring-4 ring-white">
                            <UserIcon className="h-6 w-6 text-blue-600" aria-hidden="true" />
                        </span>
                        <h1 className="text-3xl font-bold  tracking-tight text-gray-900 md:text-3xl">{greeting()} {account.first_name} {account.last_name}</h1>
                    </div>

                    {content &&
                        <p className="text-gray-600 text-lg mb-6">{content}</p>
                    }
                    {!content &&
                        <>
                            <p className="text-gray-600 text-lg mb-6">Bij Bureau voor Verduurzamen zijn we specialist in het adviseren van bewezen maatregelen om jouw woning te verduurzamen.
                                Samen met onze adviseurs krijg je persoonlijk verduurzamingsaanbod gericht op de woonsituatie.</p>
                        </>
                    }

                    {!account.finished_onboarding_at && <Onboarding account={account }/> }
                </div>
            }
        </div>
    )
}
