
import React from "react";
import Tooltip from "../Tooltip";

type props = {
    label: string
    reference: string
    icon?: 'warning'
    tooltip?: string
}

export default function InputLabel({label, reference, tooltip, icon}: props): React.ReactElement {
    return (
        <>
            {tooltip ?
                (<Tooltip text={tooltip}>
                    <label htmlFor={reference} className="inline-flex justify-between items-center text-sm font-medium text-gray-700">
                        {label}
                        {icon === 'warning' &&
                            <div className="pl-1 inline-flex items-center pointer-events-none">
                                <svg className="h-5 w-5 text-amber-300" x-description="Heroicon name: solid/exclamation-circle"
                                     xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"
                                     aria-hidden="true">
                                    <path fillRule="evenodd"
                                          d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
                                          clipRule="evenodd"></path>
                                </svg>
                            </div>
                        }
                    </label>
                </Tooltip>) :
                (<label htmlFor={reference} className="inline-flex justify-between items-center text-sm font-medium text-gray-700">
                    {label}
                    {icon === 'warning' &&
                        <div className="pl-1 inline-flex items-center pointer-events-none">
                            <svg className="h-5 w-5 text-amber-300" x-description="Heroicon name: solid/exclamation-circle"
                                 xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"
                                 aria-hidden="true">
                                <path fillRule="evenodd"
                                      d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
                                      clipRule="evenodd"></path>
                            </svg>
                        </div>
                    }
                </label>)
            }
        </>
    )
}

