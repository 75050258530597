import Breadcrumbs from "../../components/organisms/Breadcrumbs";
import {Link, useParams} from "react-router-dom";
import {
    ArrowUpIcon,
} from '@heroicons/react/solid'
import Button from "../../components/atoms/Button";
import Data from "../../utils/Data";
import toast from "react-hot-toast";
import Loader from "../../components/atoms/Loader";
import Message from "../../components/atoms/Message";
import MeasureStatus from "../../components/molecules/MeasureStatus";
import MeasureHouseFields from "../../components/molecules/MeasureHouseFields";
import MeasureSavings from "../../components/molecules/MeasureSavings";
import React, {useState, useEffect} from "react";
import MeasureRequestButton from "../../components/molecules/MeasureRequestButton";
import MeasureToggleStatus from "../../components/molecules/MeasureToggleStatus";
import Modal from "../../components/molecules/Modal";

export default function Measure(): React.ReactElement {
    let { measure, measurecategory, status } = useParams();
    const [links, setLinks] = useState<any[]>([])
    const {data, isLoading, isError } =  Data('/measures/'+measure)

    if (isError) toast.error("Kan maatregel niet laden")

    useEffect(() => {
        if(data) {
            setLinks([
                { name: 'Maatregelen', to: '/maatregelen' },
                { name: data.category.name },
                { name: data.name, to: '/maatregelen/'+measurecategory+'/'+measure },
            ]);
        }
    }, [data]);



    return (
        <div className="max-w-6xl px-4 container mx-auto">
            {status === 'aangevraagd' &&
                <Modal title="De 'maatregel' is succesvol aangevraagd" description="Bedankt voor de aanvraag."/>
            }
            {!!links.length && <Breadcrumbs links={links}/> }
            {isLoading ? <Loader/> :
                <>
                    <main className="">
                        <div className="mx-auto md:flex md:items-center md:justify-between md:space-x-5">
                            <div className="flex items-center space-x-5">
                                <div className="flex-shrink-0">
                                <span className="rounded-lg inline-flex p-2 bg-blue-100 text-teal-700 ring-white">
                                    <img alt={data.name} className="h-12 w-12 object-cover" src={data.image_url}/>
                                </span>
                                </div>
                                <div>
                                    <span className="text-gray-500 font-medium text-sm leading-none">{data.category.name}</span>
                                    <h1 className="text-2xl font-bold text-gray-900 leading-none">{data.name}</h1>
                                </div>
                            </div>
                            <div className="mt-6 flex flex-col-reverse justify-stretch space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-x-reverse sm:space-y-0 sm:space-x-3 md:mt-0 md:flex-row md:space-x-3">

                                {data.status === 'AVAILABLE' && <MeasureRequestButton category={data.category} measure={data}/>}
                                {data.status === 'OFFERED' && <Button color="green" to="/offerte" text="Offerte bekijken"/>}
                                {data.status === 'UNAVAILABLE' && <span
                                    className="inline-flex items-center px-2.5 py-0.5 bg-red-100 text-red-800 rounded-md text-sm font-medium">Deze maatregel is (nog) niet mogelijk</span> }
                            </div>
                        </div>

                        <div className="mt-8 mx-auto grid grid-cols-1 gap-10 mb-20  lg:grid-flow-col-dense lg:grid-cols-3">
                            {(data.status === 'REQUESTED' || data.status === 'OFFERED') &&
                                <div className="space-y-6 lg:col-start-1 lg:col-span-3">
                                    {data.status === 'REQUESTED' && <Message type="success" title="We bellen je binnenkort voor het maken van een afspraak. Samen met onze collega maak je een afspraak op een datum die voor jou het beste uitkomt."/> }
                                    {data.status === 'OFFERED' && <Message type="success" title="Er staat een offerte voor je klaar!"/> }
                                </div>
                            }
                            <div className="space-y-10 lg:col-start-1 lg:col-span-2">
                                <section aria-labelledby="applicant-information-title">
                                    <div className="bg-white shadow sm:rounded-lg">
                                        <div className="border-t border-gray-200">
                                            <div className="px-4 py-5 sm:px-6 flex items-center justify-between"><h2 id="applicant-information-title"
                                                 className="text-lg leading-6 font-medium text-gray-900">Algemene informatie</h2>
                                                {!!data.building.reduction_percentage &&
                                                    <div
                                                        className="inline-flex items-baseline px-2.5 py-0.5 rounded-full text-sm font-medium bg-green-100 text-green-800 md:mt-2 lg:mt-0">
                                                        <ArrowUpIcon className="-ml-1 mr-0.5 flex-shrink-0 self-center h-5 w-5 text-green-500"/>
                                                        <span className="sr-only">
                                                Energiebesparing
                                              </span>
                                                        {data.building.reduction_percentage}% energiebesparing
                                                    </div>
                                                }
                                            </div>
                                            <div className="border-t border-gray-200 flow-root px-4 py-5  sm:px-6">

                                                <p className="text-base">{data.description}</p>
                                                <div className="block mt-4">
                                                    {data.link &&
                                                        <a href={data.link}
                                                           target="_blank"
                                                           className="text-gray-700 text-base underline hover:no-underline"
                                                           rel="noreferrer">Lees
                                                            meer over {data.name.toLowerCase()}</a>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                                {(data.status !== 'UNAVAILABLE' && data.status !== 'AVAILABLE' && data.status !== 'USER_COMPLETED' && data.status !== 'UNKNOWN') && <MeasureStatus measure={data}/> }

                                {(data.status !== 'UNAVAILABLE' && data.status !== 'UNKNOWN') && <MeasureSavings measure={data}/> }
                            </div>

                            <section className="lg:col-start-3 lg:col-span-1">
                                 <MeasureHouseFields measure={data}/>
                                {(data.status === 'UNAVAILABLE' || data.status === 'AVAILABLE' || data.status === 'UNKNOWN' || data.status === 'USER_COMPLETED') && <MeasureToggleStatus measure={data}/> }
                            </section>
                        </div>
                    </main>
                </>
            }
        </div>

    )
}
