import TextInput from "../../../atoms/Input/TextInput";
import Loader from "../../../atoms/Loader";
import Button from "../../../atoms/Button";
import React, {useEffect, useState} from "react";
import {BuildingType} from "../../../../types/BuildingType";
import UpdateData from "../../../../utils/UpdateData";
import { RadioGroup } from "@headlessui/react";
import {UserIcon} from "@heroicons/react/solid";
import Message from "../../../atoms/Message";

type props = {
    building: BuildingType
    onboarding?: boolean
    nextStep?: any
}

export default function EnergyBuildingForm({building, onboarding = false, nextStep} : props ): React.ReactElement {
    const configuration = JSON.parse(localStorage.getItem('energyData') || '{}');
    const [updateLoading, setUpdateLoading]= useState(false)

    const [gasUsage, setGasUse]= useState(0)
    const [gasMonthlyCost, setGasMonthlyCost]= useState(0)
    const [electricityUsage, setElectricityUsage]= useState(0)
    const [electricityUsageCost, setElectricityUsageCost]= useState(0)

    useEffect(() => {
        // @ts-ignore
        setGasUse(building.gas_usage)
        // @ts-ignore
        setGasMonthlyCost(building.gas_monthly_cost)
        // @ts-ignore
        setElectricityUsage(building.electricity_usage)
        // @ts-ignore
        setElectricityUsageCost(building.electricity_monthly_cost)
    }, [building.gas_usage, building.gas_monthly_cost, building.electricity_usage, building.electricity_monthly_cost]);


    // @ts-ignore
    const [persons, setPersons] = useState(localStorage.getItem('persons') ? parseInt(localStorage.getItem('persons')) : 0)

    const update = (state: boolean) => {
        setUpdateLoading(state)
        if(state) {
            if(nextStep) nextStep();
        }
    }

    const updateEnergyBuilding = () => {
        UpdateData('/me/building', {gas_usage: gasUsage, gas_monthly_cost: gasMonthlyCost, electricity_usage: electricityUsage, electricity_monthly_cost: electricityUsageCost}, 'Energieverbruik is bijgewerkt', 'Kan energieverbruik niet bijwerken', update)
    }

    function setEnergyUseBasedOnPersons(i: number) {
        setPersons(i);
        localStorage.setItem('persons', i+'');
        setGasUse(configuration.gasUsePerPerson[i])
        setGasMonthlyCost(configuration.gasUsePerPerson[i]*configuration.gasPrice/12)
        setElectricityUsage(configuration.electricityUsePerPerson[i])

        const electricityUsage = (((configuration.electricityUsePerPerson[i])*configuration.electricityPrice)-configuration.energyTaxDiscount)/12-configuration.energyFixedCharge;

        setElectricityUsageCost(Math.round(electricityUsage * 100) / 100)
    }

    return (
        <form action="#" className={`${onboarding ? 'mt-4' : 'mb-12'}`} id="energieverbruik" method="POST">
            <div className={`${onboarding ? '' : 'shadow sm:rounded-md sm:overflow-hidden'}`}>
                <div className={`${onboarding ? 'space-y-6' : 'bg-white py-6 px-4 space-y-6 sm:p-6'}`}>
                    {!onboarding &&
                        <div>
                            <h3 className="text-lg leading-6 font-medium text-gray-900">Energieverbruik</h3>
                            <p className="mt-1 text-sm text-gray-500">Om te berekenen hoeveel je kunt besparen met het doorvoeren van duurzame maatregelen hebben we je jaarlijkse energieverbruik nodig. Als je dit niet weet kun je ook het aantal personen van je huishouden invullen.
                            </p>
                        </div>
                    }
                    <div>
                        <div className="grid grid-cols-6 gap-6 mt-8">
                            <div className="col-span-12 sm:col-span-3">
                                <TextInput suffix="m3" onChange={(e: any) => {
                                    setGasUse(e.target.value);
                                }} label="Gasverbruik (per jaar)" type="text" value={gasUsage} tooltip="Als de woning gasvrij is vul hier dan 0 in." required reference="gas_usage"/>
                                <p className="mt-2 text-sm sm:hidden text-gray-500">Als de woning gasvrij is vul hier dan 0 in.</p>
                            </div>
                            <div className="col-span-12 sm:col-span-3">
                                <TextInput prefix="&euro;" onChange={(e: any) => {
                                    setGasMonthlyCost(e.target.value);
                                }} label="Geschatte maandbedrag gasverbruik" type="text" value={gasMonthlyCost} tooltip="Als de woning gasvrij is vul hier dan 0 in." required reference="gas_monthly_cost"/>
                                <p className="mt-2 text-sm sm:hidden text-gray-500">Als de woning gasvrij is vul hier dan 0 in.</p>
                            </div>
                        </div>

                        <div className="grid grid-cols-6 gap-6 mt-8">
                            <div className="col-span-12 sm:col-span-3">
                                <TextInput suffix="kWh" onChange={(e: any) => {
                                    setElectricityUsage(e.target.value);
                                }} label="Stroomverbruik (per jaar)" type="text" value={electricityUsage} required reference="electricity_usage"/>
                            </div>
                            <div className="col-span-12 sm:col-span-3">
                                <TextInput prefix="&euro;" onChange={(e: any) => {
                                    setElectricityUsageCost(e.target.value);
                                }} label="Geschatte maandbedrag stroomverbruik" type="text" value={electricityUsageCost} required reference="electricity_usage_cost"/>
                            </div>
                        </div>
                    </div>
                    {configuration &&
                        <div>
                            <div className="flex items-center justify-between">
                                <h2 className="text-sm font-medium text-gray-900">Of schat verbruik op basis van aantal personen</h2>
                            </div>

                            <RadioGroup className="mt-2 w-full md:w-8/12">
                                <RadioGroup.Label className="sr-only">Aantal personen</RadioGroup.Label>
                                <div className="grid grid-cols-3 gap-3 sm:grid-cols-5">
                                    {[...Array(5)].map((x, i) =>
                                        <RadioGroup.Option
                                            key={i}
                                            value={i}
                                            onClick={() => setEnergyUseBasedOnPersons(i)}
                                            className={` ${persons === i ? 'bg-blue-600 border-transparent text-white hover:bg-blue-700' : 'bg-white border-gray-200 text-gray-900 hover:bg-gray-50'} border rounded-md py-4 px-3 flex items-center cursor-pointer justify-center text-sm font-medium uppercase sm:flex-1`}
                                        >
                                            <RadioGroup.Label as="span" className="flex">
                                                <>
                                                    {[...Array(i+1)].map((y, z) =>
                                                        <UserIcon key={z} className="text-white-500 w-5 h-5"/>
                                                    )}
                                                </>
                                            </RadioGroup.Label>
                                        </RadioGroup.Option>
                                    )}
                                </div>
                            </RadioGroup>
                        </div>
                    }
                    <Message title="Je kunt je verbruik in veel gevallen vinden in de app van je energieleverancier." type="info"/>
                </div>
                <div className={`${onboarding ? 'flex justify-end mt-6' : 'px-4 py-3 bg-gray-50 flex justify-end items-center text-right sm:px-6'}`}>
                    <div className="flex items-center">
                        {updateLoading && <Loader inline size="small"/> }
                        <div className="ml-2">
                            <Button onClick={() => updateEnergyBuilding()} color="green" text="Opslaan"/>
                        </div>
                    </div>

                </div>
            </div>
        </form>
    )
}

