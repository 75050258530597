import MyHouseCard from "../../components/molecules/MyHouseCard";
import WelcomeCard from "../../components/molecules/WelcomeCard";
import MeasureCategory from "../../components/organisms/MeasureCategory";
import Data from "../../utils/Data";
import toast from "react-hot-toast";
import Loader from "../../components/atoms/Loader";
import React from "react";
import ContentCard from "../../components/molecules/ContentCard";
import AdviceCard from "../../components/molecules/AdviceCard";

export default function Home(): React.ReactElement {
    const {data, isLoading, isError } =  Data('/measure-categories')


    if (isError) toast.error("Kan categorieën niet laden")

    return (
        <div className="max-w-8xl px-4 container mx-auto">
            <div className="mb-20 grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-2">
                <WelcomeCard/>
                <MyHouseCard/>
            </div>

            <div className="mb-20">
                {isLoading ? <Loader/> :
                    <>
                        {data.map((category: any, index: number) => (
                            <MeasureCategory key={index} index={index} category={category}/>
                        ))}
                    </>
                }
            </div>

            <div className="mb-20 grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-2">
                <AdviceCard title="Weet je niet welke maatregel aan te vragen?" description="Onze adviseur doet tijdens een telefonisch gesprek een digitale woningopname om de situatie in kaart te brengen.
Op basis van de situatie krijg indicatie van de beste duurzame mogelijkheden, rekening houdend met de woonsituatie en wensen. Naast het advies ontvang je direct een richt offerte
voor de mogelijke maatregelen."/>
                <ContentCard linkText="Meer informatie over subsidie" linkColor="gray" linkTarget="__blank" linkUrl="https://bureauverduurzamen.nl/subsidie" title="ISDE subsidie" description="Iedere woningeigenaar die voor minimaal 2 grote isolatiemogelijkheden kiest komt in aanmerking voor deze isolatiesubsidie. Met de ISDE-subsidie kun je tot wel 30 procent van de totale investering terugkrijgen.
Ook is het mogelijk om één isolatiemaatregel te combineren met een warmtepomp, zonneboiler of aansluiting op een warmtenet."/>
            </div>
        </div>
    )
}
