import TextInput from "../../../atoms/Input/TextInput";
import Loader from "../../../atoms/Loader";
import Button from "../../../atoms/Button";
import React, {useEffect, useState} from "react";
import {BuildingType} from "../../../../types/BuildingType";
import UpdateData from "../../../../utils/UpdateData";
import SelectInput from "../../../atoms/Input/SelectInput";
import useScrollToLocation from "../../../../utils/ScrollToLocation";

type props = {
    building: BuildingType
}

export default function OwnershipForm({building} : props ): React.ReactElement {
    const [updateLoading, setUpdateLoading]= useState(false)
    const [hasHoaPermission, setHasHoaPermission]= useState(building.has_hoa_permission)
    const [isHoaMember, setIsHoaMember]= useState(!!building.is_hoa_member)
    const [isOwnedProperty, setIsOwnedProperty]= useState(!!building.is_owned_property)

    const [hoaPocEmail, setHoaPocEmail]= useState(building.hoa_poc_email)
    const [hoaPocPhone, setHoaPocPhone]= useState(building.hoa_poc_phone)
    const [hoaPocName, setHoaPocName]= useState(building.hoa_poc_name)

    useScrollToLocation()

    const updateOwnership = () => {
        UpdateData('/me/building', {has_hoa_permission: hasHoaPermission, is_hoa_member: isHoaMember, is_owned_property: isOwnedProperty, hoa_poc_email: hoaPocEmail, hoa_poc_phone: hoaPocPhone, hoa_poc_name: hoaPocName}, 'Eigendomsinformatie is bijgewerkt', 'Kan eigendomsinformatie niet bijwerken', setUpdateLoading)
    }

    return (
        <form action="#" id="eigendomsinformatie" className="mb-12" method="POST">
            <div className="shadow sm:rounded-md sm:overflow-hidden">
                <div className="bg-white py-6 px-4 space-y-6 sm:p-6">
                    <div>
                        <h3 className="text-lg leading-6 font-medium text-gray-900">Eigendomsinformatie</h3>
                        <p className="mt-1 text-sm text-gray-500">Dit zijn de gegevens over de eigenaarsinformatie van de woning.</p>
                    </div>
                        <div className="">
                            <fieldset>
                                <div className="grid grid-cols-6 gap-6">
                                    <div className="col-span-12 flex mt-4 mb-4 sm:col-span-3">
                                        <div className="flex items-center h-5">
                                            <input id="ownedproperty" name="ownedproperty" type="checkbox"
                                                   onChange={(e: any) => {
                                                       setIsOwnedProperty(e.target.checked);
                                                   }}
                                                   checked={isOwnedProperty}
                                                   className="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300 rounded"/>
                                        </div>
                                        <div className="ml-3 text-sm">
                                            <label htmlFor="ownedproperty"
                                                   className="font-medium text-gray-700">Eigenaarschap woning
                                                <p className="text-gray-500">Ben je de eigenaar van de woning?</p></label>
                                        </div>
                                    </div>
                                </div>
                            </fieldset>
                            <fieldset>
                                <div className="grid grid-cols-6 gap-6">
                                    <div className="col-span-12 mt-4 flex sm:col-span-3">
                                        <div className="flex items-center h-5">
                                            <input id="hoamember" name="hoamember" type="checkbox"
                                                   onChange={(e: any) => {
                                                       setIsHoaMember(e.target.checked);
                                                   }}
                                                   checked={isHoaMember}
                                                   className="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300 rounded"/>
                                        </div>
                                        <div className="ml-3 text-sm">
                                            <label htmlFor="hoamember"
                                                   className="font-medium text-gray-700">Lid van VvE
                                                <p className="text-gray-500">Ben je lid van een vereniging van eigenaren?</p></label>
                                        </div>
                                    </div>
                                    {isHoaMember &&
                                        <>
                                            <div className="col-span-12 flex mt-4 sm:col-span-3">
                                                <div className="flex items-center h-5">
                                                    <input id="hoapermission" name="hoapermission" type="checkbox"
                                                           onChange={(e: any) => {
                                                               setHasHoaPermission(e.target.checked);
                                                           }}
                                                           checked={hasHoaPermission}
                                                           className="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300 rounded"/>
                                                </div>
                                                <div className="ml-3 text-sm">
                                                    <label htmlFor="hoapermission"
                                                           className="font-medium text-gray-700">Toestemming VvE
                                                        <p className="text-gray-500">Heb je toestemming van de VvE?</p></label>
                                                </div>
                                            </div>
                                        </>

                                    }

                                </div>
                            </fieldset>
                            {isHoaMember &&
                                <>

                                    <div className="grid grid-cols-6 gap-6 mt-4">
                                        <h3 className="col-span-12 mt-4 sm:col-span-6 leading-6 font-medium text-gray-900">Contactgegevens vertegenwoordiger VvE</h3>
                                        <div className="col-span-12 sm:col-span-6">
                                            <TextInput onChange={(e: any) => {
                                                setHoaPocName(e.target.value);
                                            }} label="Volledige naam" defaultValue={building.hoa_poc_name} type="text" required
                                                       reference="hoapocname"/>
                                        </div>
                                        <div className="col-span-12 mt-4 sm:col-span-3">
                                            <TextInput onChange={(e: any) => {
                                                setHoaPocEmail(e.target.value);
                                            }} label="E-mailadres" defaultValue={building.hoa_poc_email} type="email" required
                                                       reference="hoapocemail"/>
                                        </div>
                                        <div className="col-span-12 mt-4 sm:col-span-3">
                                            <TextInput onChange={(e: any) => {
                                                setHoaPocPhone(e.target.value);
                                            }} label="Telefoonnummer" defaultValue={building.hoa_poc_phone} type="text" required
                                                       reference="hoapocphone"/>
                                        </div>
                                    </div>
                                </>

                            }

                    </div>
                </div>
                <div className="px-4 py-3 bg-gray-50 flex justify-end items-center text-right sm:px-6">
                    <div className="flex items-center">
                        {updateLoading && <Loader inline size="small"/> }
                        <div className="ml-2">
                            <Button onClick={() => updateOwnership()} color="green" text="Opslaan"/>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    )
}

