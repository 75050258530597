import Loader from "../Loader";
import {BadgeCheckIcon} from "@heroicons/react/solid";

type props = {
    current: number
}

export default function MeasureCardProgress({current} : props): React.ReactElement {

    const currentLabel = ['Kan aangevraagd worden', 'Aangevraagd', 'Openstaande offerte', 'Maatregel uitgevoerd']

    let steps = [
        { key: 1, name: 'Kan aangevraagd worden', status: 'upcoming' },
        { key: 2, name: 'Maatregel aangevraagd', status: 'upcoming' },
        { key: 3, name: 'Openstaande offerte', status: 'upcoming' },
        { key: 4, name: 'Wordt uitgevoerd', status: 'upcoming' },
    ]

    steps = steps.map((step, i) => {
        if(i+1 < current) step.status = 'complete'
        if(i+1 > current) step.status = 'upcoming'
        if(i+1 === current) step.status = 'current'
        return step
    });

    return (
        <nav className="flex items-center justify-between mb-6" aria-label="Progress">
                        <span
                            className={`inline-flex items-center px-2.5 py-0.5 ${current === 1 || current === 4 ? 'bg-green-100 text-green-800' : 'bg-sky-100 text-sky-800'} rounded-md text-sm font-medium`}>
                                <BadgeCheckIcon className="w-4 h-4 mr-1"/>
                            {currentLabel[current-1]}</span>
            {(current !== 1 && current !== 4) &&
                <ol role="list" className="ml-4 flex items-center space-x-5">
                    {steps.map((step) => (
                        <li key={step.name}>
                            {step.status === 'complete' ? (
                                <span className="block w-2.5 h-2.5 bg-green-500 rounded-full">
                                <span className="sr-only">{step.name}</span>
                            </span>
                            ) : step.status === 'current' ? (
                                <span className="relative flex items-center justify-center" aria-current="step">
                <span className="absolute w-5 h-5 p-px flex" aria-hidden="true">
                  <span className="w-full h-full rounded-full bg-green-200" />
                </span>
                                <span className="relative block w-2.5 h-2.5 bg-green-500 rounded-full" aria-hidden="true" />
                                <span className="sr-only">{step.name}</span>
                            </span>
                            ) : (
                                <span className="block w-2.5 h-2.5 bg-gray-200 rounded-full">
                                <span className="sr-only">{step.name}</span>
                            </span>
                            )}
                        </li>
                    ))}
                </ol>
            }
        </nav>
    )
}
