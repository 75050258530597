import {HomeIcon, ChevronRightIcon} from "@heroicons/react/solid";
import {Link} from "react-router-dom";

type props = {
    links: Array<any>
}

export default function Nav({links}: props): React.ReactElement {
    return (
        <nav className="flex mb-6 md:mb-10" aria-label="Breadcrumb">
            <ol role="list" className="flex items-center space-x-4">
                <li>
                    <div>
                        <Link to="/" className="text-gray-400 hover:text-gray-500">
                            <HomeIcon className="flex-shrink-0 h-5 w-5" aria-hidden="true" />
                            <span className="sr-only">Home</span>
                        </Link>
                    </div>
                </li>
                {links.map((page) => (
                    <li key={page.name}>
                        <div className="flex items-center">
                            <ChevronRightIcon className="flex-shrink-0 h-5 w-5 text-gray-400" aria-hidden="true" />
                            {page.to &&
                                <Link
                                    to={page.to}
                                    className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"
                                    aria-current={page.current ? 'page' : undefined}
                                >
                                    {page.name}
                                </Link>
                            }
                            {!page.to &&
                                <div
                                    className="ml-4 text-sm font-medium text-gray-500"
                                >
                                    {page.name}
                                </div>
                            }

                        </div>
                    </li>
                ))}
            </ol>
        </nav>
    )
}
