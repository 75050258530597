import React, {useEffect} from "react";
import {Outlet, useLocation} from "react-router-dom";
import {Toaster} from "react-hot-toast";
import SupportButton from "./components/atoms/SupportButton";
export default function App(): React.ReactElement {
    const { pathname } = useLocation();
    useEffect(() => {
        if(!window.location.hash) {
            window.scrollTo(0, 0);
        }

    }, [pathname]);

    return (
        <div className="scroll-smooth">
            <Toaster position="bottom-right"/>
           <Outlet/>
            <SupportButton/>
        </div>

    )
}
