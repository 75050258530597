import React, {useState} from "react";
import InputLabel from "./InputLabel";

type props = {
    placeholder?: string
    label: string
    reference: string
    type: string
    required: boolean
    defaultValue?: string | number
    readOnly?: boolean
    onChange?: any
    suffix?: string
    prefix?: string
    disabled?: boolean
    icon?: 'warning'
    value?: any
    tooltip?: string
}

export default function TextInput({placeholder, suffix, prefix, value, label, readOnly, reference, tooltip, type, required, defaultValue, disabled, onChange, icon}: props): React.ReactElement {


    return (
        <div>
            <InputLabel reference={reference} tooltip={tooltip} icon={icon} label={label} />
            <div className="mt-1 relative">
                {prefix && (
                    <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                      <span className="text-gray-500 sm:text-sm" id="price-currency">
                        {prefix}
                      </span>
                    </div>
                )}
                <input
                    defaultValue={defaultValue}
                    onChange={onChange}
                    id={reference}
                    disabled={disabled}
                    value={value}
                    readOnly={readOnly}
                    name={reference}
                    type={type}
                    placeholder={placeholder}
                    required={required}
                    className={`${readOnly ? 'cursor-not-allowed' : ''} ${prefix ? 'pl-8' : 'pl-3'} ${suffix ? 'pr-12' : 'pr-3'} appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm appearance-none`}
                />
                {suffix && (
                    <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                      <span className="text-gray-500 sm:text-sm" id="price-currency">
                        {suffix}
                      </span>
                    </div>
                )}
            </div>
        </div>
    )
}
