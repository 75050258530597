import Button from "../../atoms/Button";
import {Link} from "react-router-dom";
import MeasureCardProgress from "../../atoms/MeasureCardProgress";
import {ArrowUpIcon, CheckIcon, InformationCircleIcon} from "@heroicons/react/solid";
import MeasureRequestButton from "../MeasureRequestButton";

type props = {
    category: any
    measure: any
    unknown?: boolean
    recommended?: boolean
}

export default function AvailableMeasureCard({measure, category, unknown = false, recommended = false }: props): React.ReactElement {
    function getSlug() {
        if(!unknown) return '/maatregelen/'+category.slug+'/'+measure.slug;
        return ''
    }

    const returnInvestment = () => {
        const investment = measure.building.total_investment-(measure.subsidy.per_unit*measure.building.quantity);
        return Math.ceil(investment / measure.calculations.estimated_yearly_savings);
    }

    return (
        <div className={` ${recommended? 'p-6 pb-14 border-2 border-green-500' : 'p-6'} hover:opacity-90 h-full transition-all col-span-1 flex justify-between flex-col relative  bg-white rounded-lg shadow`}>
            <Link to={getSlug()}>
                <div className="mb-6">
                    {(!unknown && !recommended) && <MeasureCardProgress current={1}/> }
                    <div className="flex items-center">
                <span className="mr-3 flex-none rounded-lg inline-flex p-1 bg-blue-100 text-teal-700 ring-2 ring-white">
                    <img alt={measure.name} className="h-8 w-8 object-cover" src={measure.image_url}/>
                </span>
                        <div>
                            <h4 className="text-xxl font-bold tracking-tight text-gray-900 md:text-2xl leading-tight md:leading-tight">{measure.name}</h4>
                            {!unknown && !!measure.calculations.estimated_yearly_savings && returnInvestment() < 11 &&
                                <span className="text-xs font-medium text-gray-500 leading-none">Binnen +/- {returnInvestment()} jaar terugverdiend*</span> }
                        </div>
                    </div>
                </div>
                {!unknown &&
                    <div className="grid grid-cols-1 gap-4 mb-6 sm:grid-cols-2">
                        {!!measure.building.reduction_percentage &&
                            <div>
                                <span className="text-sm block font-medium text-gray-500 truncate">Energiebesparing</span>
                                <div
                                    className="inline-flex items-baseline px-2.5 py-0.5 rounded-full text-md font-medium bg-green-100 text-green-800 md:mt-1">

                                    <ArrowUpIcon className="-ml-1 mr-0.5 flex-shrink-0 self-center h-5 w-5 text-green-500"/>
                                    <span className="sr-only">
                    Besparing
                  </span>
                                    {measure.building.reduction_percentage}%
                                </div>
                            </div>
                        }

                        {measure.calculations.estimated_yearly_yield &&
                            <div>
                                <span className="text-sm block font-medium text-gray-500 truncate">Opbrengst per jaar</span>
                                <span className="relative text-xl mt-1 block font-semibold text-gray-900">
                                 <span>{Math.floor(measure.calculations.estimated_yearly_yield)} kWh</span>
                            </span>
                            </div>
                        }
                        {measure.calculations.estimated_yearly_savings &&
                            <div>
                                <span className="text-sm block font-medium text-gray-500 truncate">Besparing per jaar</span>
                                <span className="relative text-xl mt-1 block font-semibold text-gray-900">
                                     <span>&euro; {Math.floor(measure.calculations.estimated_yearly_savings)}</span>
                                </span>
                            </div>
                        }
                        {!measure.calculations.estimated_yearly_savings && !measure.calculations.estimated_yearly_yield &&
                            <div>
                                <span className="text-sm block font-medium text-gray-500 truncate">Besparing/opbrengst</span>
                                <span className="relative text-xl mt-1 block font-semibold text-gray-900 blur-sm">
                                     <span>&euro; 100</span>
                                </span>
                            </div>
                        }
                    </div>
                }
            </Link>
            <MeasureRequestButton color={recommended ? 'green': 'blue'} category={category} measure={measure}/>
            {recommended && <div className="absolute w-full flex items-center justify-center text-green-700 font-medium text-sm text-center bg-green-200 px-1 rounded-b py-2 bottom-0 left-0 right-0">
                <CheckIcon className="w-5 h-5 mr-1"/>
                Aanbevolen als volgende stap</div> }
        </div>
    )
}


