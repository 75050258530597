import React from "react";
import {Outlet} from "react-router-dom";
import Nav from "../../components/organisms/Nav";
import LoginPage from "../../pages/Auth/Login";
import Loader from "../../components/atoms/Loader";
import Login from "../../utils/Login";


export default function BaseLayout(): React.ReactElement {
    if(!localStorage.getItem('authtoken')) return <LoginPage/>
    if(localStorage.getItem('authtoken')) {
        const { data, isLoading } = Login(localStorage.getItem('authtoken'));

        return (
            <div>
                {isLoading ? <div className="w-screen h-screen"><Loader/></div> :
                    <div>
                        <Nav/>
                        <div className="mt-12">
                            {data ? <Outlet/> : <LoginPage/>}
                        </div>
                    </div>
                }
            </div>

        )
    } else {
        return <LoginPage/>
    }



}
