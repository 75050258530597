import React from 'react';
import * as ReactDOMClient from 'react-dom/client';
import {
    createBrowserRouter,
    RouterProvider,
} from "react-router-dom";
import "./App.css";

import Error from "./pages/Error";
import Home from "./pages/Home";
import BaseLayout from "./layouts/Base";
import Login from "./pages/Auth/Login";
import Invoices from "./pages/Invoices";
import MyBuilding from "./pages/MyHouse";
import App from "./App";
import Measures from "./pages/Measures";
import Measure from "./pages/Measure";
import VerifyLogin from "./pages/Auth/VerifyLogin";
import MyAccount from "./pages/MyAcount";
import Faq from "./pages/Faq";

const router = createBrowserRouter([
    {
        path: "",
        element: <App />,
        errorElement: <Error/>,
        children: [
            {
                path: "/auth",

                errorElement: <Error/>,
                children: [
                    {
                        path: "login",
                        children: [
                            {
                                path: ":token",
                                element: <VerifyLogin/>,
                            },
                            {
                                index: true,
                                element: <Login/>,
                            },
                        ]
                    }
                ]
            },
            {
                path: "/",
                element: <BaseLayout />,
                errorElement: <Error/>,
                children: [
                    {
                        index: true,
                        element: <Home />,
                    },
                    {
                        path: "/veelgestelde-vragen",
                        children: [
                            {
                                index: true,
                                element: <Faq />,
                            },
                        ]
                    },
                    {
                        path: "/offertes",
                        children: [
                            {
                                index: true,
                                element: <Invoices />,
                            },
                            // {
                            //     path: ":id",
                            //     element: <Invoice/>
                            // }
                        ]
                    },
                    {
                        path: "/maatregelen",
                        children: [
                            {
                                index: true,
                                element: <Measures showAll />,
                            },
                            {
                                path: ":measurecategory",
                                children: [
                                    {
                                        index: true,
                                        element: <Measures />,
                                    },
                                    {
                                        path: ":measure",
                                        element: <Measure/>,
                                    },
                                    {
                                        path: ":measure/:status",
                                        element: <Measure/>,
                                    },


                                ]
                            }
                        ]
                    },
                    {
                        path: "/mijn-woning",
                        children: [
                            {
                                index: true,
                                element: <MyBuilding />,
                            },
                        ]
                    },
                    {
                        path: "/mijn-account",
                        children: [
                            {
                                index: true,
                                element: <MyAccount />,
                            },
                        ]
                    }
                ],
            },
            ]
    }
]);

// @ts-ignore
const root = ReactDOMClient.createRoot(document.getElementById('root') as HTMLElement);

root.render(
    <React.StrictMode>
        <div>
            <RouterProvider router={router} />
        </div>
    </React.StrictMode>
);
