import Button from "../../components/atoms/Button";
import Breadcrumbs from "../../components/organisms/Breadcrumbs";
import Data from "../../utils/Data";
import Loader from "../../components/atoms/Loader";
import React from "react";
import {ConvertDateTime} from "../../utils/ConvertDateTime";

export default function Invoices(): React.ReactElement {
    const links = [
        { name: 'Offertes', to: '/offertes' },
    ]
    const { data, isLoading } =  Data('/quotes')

    return (
        <div className="px-4 max-w-7xl container mx-auto">
            <Breadcrumbs links={links} />
            <div className="sm:flex sm:items-center">
                <div className="sm:flex-auto">
                    <h1 className="text-xl font-semibold text-gray-900">Openstaande offertes</h1>
                </div>

            </div>
                    <div className="-mx-4 mt-8 overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:-mx-6 md:mx-0 md:rounded-lg">
                        <table className="min-w-full divide-y divide-gray-300">
                            <thead className="bg-gray-50">
                            <tr>
                                <th scope="col" className="px-3 pl-4 py-3.5 text-left text-sm font-semibold text-gray-900">
                                    Referentie
                                </th>
                                <th scope="col" className="px-3 pl-4 py-3.5 text-left text-sm font-semibold text-gray-900">
                                    Maatregel
                                </th>
                                <th
                                    scope="col"
                                    className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell"
                                >
                                    Verzenddatum
                                </th>
                                <th
                                    scope="col"
                                    className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell"
                                >
                                    Verloopt op
                                </th>
                                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold  text-gray-900">
                                    Totaalbedrag
                                </th>
                                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold  text-gray-900">

                                </th>

                            </tr>
                            </thead>

                            <tbody className="divide-y divide-gray-200 bg-white">
                            {
                                isLoading ? <th colSpan={6} scope="colgroup"
                                                className="bg-white-50 text-center px-4 py-4 text-left text-sm font-normal sm:px-6"><Loader/></th> :
                                <>
                                    {!data ? (<th colSpan={6} scope="colgroup"
                                                  className="bg-white-50 text-center px-4 py-4 text-left text-sm font-normal sm:px-6">Er zijn geen openstaande offertes.</th>) :
                                        (<>{data.map((invoice: any) => (
                                            <a target="_blank" title={invoice.quote_number} href={invoice.url} className="table-row w-full hover:bg-slate-50" key={invoice.reference}>
                                        <td className="w-full md:hidden max-w-0 py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:w-auto sm:max-w-none sm:pl-6">
                                            {/*{invoice.measure.title}*/}
                                            <dl className="font-normal lg:hidden">
                                                <dt className="sr-only">Maatregel</dt>
                                                <dd className="mt-1 truncate text-gray-700">{invoice.quote_number}</dd>
                                                <dt className="sr-only sm:hidden">Verstuurd op</dt>
                                                <dd className="mt-1 truncate text-gray-500 sm:hidden">
                                                    {invoice.total_price}
                                                </dd>
                                            </dl>
                                        </td>
                                        <td className="hidden px-3 py-4 text-base text-gray-500 lg:table-cell">

                                            <span
                                                className="inline-flex items-center px-2.5 py-0.5 bg-sky-100 text-sky-800 rounded-md text-sm font-medium">{invoice.quote_number}</span>
                                            </td>
                                                <td className="hidden px-3 py-4 text-gray-500 text-base lg:table-cell">{invoice.measure.name}</td>
                                        <td className="hidden px-3 py-4 text-base text-gray-500 sm:table-cell">{ConvertDateTime(invoice.issued_at, 'DD-MM-YYYY')}</td>
                                        <td className="hidden px-3 py-4 text-base text-gray-500 sm:table-cell">{ConvertDateTime(invoice.expires_at, 'DD-MM-YYYY')}</td>
                                                <td className="hidden px-3 py-4 text-base text-gray-500 sm:table-cell">&euro;{invoice.total_price}</td>
                                        <td className="py-4 pl-3 pr-4 text-right text-base text-gray-600 sm:pr-6">
                                            <Button target="_blank" href={invoice.url} color="green" text="Bekijk offerte"/>
                                        </td>
                                    </a>
                                ))}</>)
                                    }
                                </>
                            }
                            </tbody>
                        </table>
                    </div>
        </div>
    )
}
