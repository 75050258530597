import TextInput from "../../../atoms/Input/TextInput";
import Loader from "../../../atoms/Loader";
import Button from "../../../atoms/Button";
import React, {useEffect, useState} from "react";
import UpdateData from "../../../../utils/UpdateData";
import SelectInput from "../../../atoms/Input/SelectInput";
import Data from "../../../../utils/Data";
import toast from "react-hot-toast";
import useScrollToLocation from "../../../../utils/ScrollToLocation";
import BooleanInput from "../../../atoms/Input/BooleanInput";

export default function MetaForm(): React.ReactElement {
    const [updateLoading, setUpdateLoading]= useState(false)
    const {data, isLoading, isError } =  Data('/me/building/meta')
    const [allMetaFields, setAllMetaFields] = useState<any[]>([])
    const [metaFields, setMetaFields] = useState({})

    useScrollToLocation()
    function setMetaValue({category, key, value, type}: { category: any, key: any, value: any, type: any }) {
        const fields = allMetaFields.map((obj, index) => {
          if(obj.measure === category) {
              obj.fields.map((field: any) => {
                  if(field.key === key) {
                      field.value = value;
                  }
              })
          }
          return obj
        })
        setAllMetaFields(fields)
        setMetaFields({...metaFields, [key]:value});
    }

    useEffect(() => {
        setAllMetaFields(data)
    }, [data])

    if (isError) toast.error("Kan woning specifieke velden niet laden")

    const updateMeta = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        console.log(metaFields)
        UpdateData('/me/building/meta', metaFields, 'Maatregel specifieke velden zijn bijgewerkt', 'Kan maatregel specifieke velden niet bijwerken', setUpdateLoading, setAllMetaFields)
    }


    function getMetaIcon(source: string) {
        if(source === 'CALCULATED') {
            return 'warning'
        }
    }

    return (
        <>
            {
                isLoading ? <Loader/> :
                    <form action="#" id="meta" className="mb-12" method="POST">
                        <div className="shadow sm:rounded-md">
                            <div className="bg-white py-6 px-4 space-y-6 sm:p-6">
                                <div>
                                    <h3 className="text-lg leading-6 font-medium text-gray-900">Maatregel specifieke
                                        informatie</h3>
                                    <p className="mt-1 text-sm text-gray-500">Dit zijn specifieke gegevens over technische eigenschappen van de woning voor maatregelen zoals die over de spouwmuur of vloer. Op basis van deze gegevens kunnen we zeer nauwkeurig een schatting van de investering en besparing maken. Probeer daarom zo nauwkeurig mogelijk te schatten.</p>
                                </div>

                                <div className="">
                                    {allMetaFields?.map((fields: any) => {
                                        console.log(fields)
                                        if(fields.fields) {
                                            return <div className="mb-12 pb-12 border-b border-gray-200">
                                                <h4 className="text-base leading-6 font-medium text-gray-900">{fields.label}</h4>
                                                <div className="grid grid-cols-6 gap-6">
                                                    {fields?.fields.map((field: any) => {
                                                        return <div key={field.key} className="col-span-12 mt-4 sm:col-span-3">
                                                            {field.type === 'number' &&
                                                                <TextInput onChange={(e: any) => {
                                                                    setMetaValue({category: fields.measure, key: field.key, value: e.target.value, type: field.type});
                                                                }}  suffix={field.unit !== 'zonnepanelen' ? field.unit : null} label={field.label} tooltip={field.helptext} defaultValue={field.value} icon={getMetaIcon(field.source)} type={field.type} required reference={field.key}/>
                                                            }
                                                            {field.type === 'boolean' &&
                                                                <BooleanInput onChange={(e: any) => {
                                                                    setMetaValue({category: fields.measure, key: field.key, value: e.target.checked, type: field.type});
                                                                }} label={field.label} tooltip={field.helptext} icon={getMetaIcon(field.source)} value={field.value} required reference={field.key}/>
                                                            }
                                                            {field.type === 'choice' &&
                                                                <SelectInput onChange={(e: any) => {
                                                                    setMetaValue({category: fields.measure, key: field.key, value: e.target.value, type: field.type});
                                                                }} label={field.label} icon={getMetaIcon(field.source)} metaField defaultValue={field.value} options={field.options} tooltip={field.helptext} required reference={field.key}/>
                                                            }

                                                            {field.helptext &&
                                                                <p className="mt-2 md:hidden text-sm text-gray-500">{field.helptext}</p>
                                                            }
                                                        </div>
                                                    })}
                                                </div>

                                            </div>
                                        } else {
                                            return <></>
                                        }

                                    })}

                                </div>
                                <div className="mt-2 flex items-center text-sm text-yellow-700 rounded-md bg-yellow-50 p-4">
                                    <div className="pr-2 inline-flex items-center pointer-events-none">
                                        <svg className="h-5 w-5 text-amber-400" x-description="Heroicon name: solid/exclamation-circle"
                                             xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"
                                             aria-hidden="true">
                                            <path fillRule="evenodd"
                                                  d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
                                                  clipRule="evenodd"></path>
                                        </svg>
                                    </div>Velden gemarkeerd met dit icoon zijn automatisch berekend op basis van je woningegevens en dienen als een schatting.</div>
                            </div>
                            <div className="px-4 py-3 bg-gray-50 flex justify-end items-center text-right sm:px-6">
                                <div className="flex items-center">
                                    {updateLoading && <Loader inline size="small"/>}
                                    <div className="ml-2">
                                        <Button onClick={() => updateMeta()} color="green" text="Opslaan"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
            }
        </>
    )
}

