import {CheckIcon, PencilIcon, ThumbUpIcon} from "@heroicons/react/solid";
import {Link} from "react-router-dom";
import Loader from "../Loader";

type props = {
    text: string
    color?: string
    icon?: 'thumbs-up' | 'edit' | 'save'
    size?: 'small' | 'medium' | 'large'
    to?: string
    onClick?: any
    target?: string
    href?: string
    loading?: boolean
    disabled?: boolean
}

export default function Button({text, disabled = false, color = 'blue', target, href, icon, size = 'medium', to, onClick, loading }: props): React.ReactElement {
    const iconStyling = '-ml-0.5 mr-2 h-4 w-4';
    const getIconFromName = (name: string) => {
        switch (name) {
            case 'thumbs-up':
                return <ThumbUpIcon className={iconStyling}/>;
            case 'edit':
                return <PencilIcon className={iconStyling}/>;
            case 'save':
                return <CheckIcon className={iconStyling}/>;
        }
    }

    const getColor = () => {
        switch (color) {
            case 'green':
                return 'bg-green-600 hover:bg-green-700  focus:ring-green-500 text-white';
            case 'blue':
                return 'bg-blue-600 hover:bg-blue-700  focus:ring-blue-500 text-white';
            case 'gray':
                return 'border-gray-300 text-gray-700 bg-white hover:bg-gray-50 focus:ring-blue-500';
        }
    }

    const getPadding = (size: string) => {
        switch (size) {
            case 'small':
                return 'px-2 py-1'
            case 'medium':
                return 'px-4 py-2'
            case 'large':
                return 'px-6 py-3'
        }
    }

    return (
        <div className="inline-block flex-none" onClick={onClick}>
            {to &&
                <Link to={to} type="button"
                      className={`inline-flex items-center ${getColor()} ${getPadding(size)} border border-transparent text-sm font-medium rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 ${disabled ? 'cursor-not-allowed pointer-events-none' : ''}`}>
                    {icon && getIconFromName(icon) }
                    {text}
                </Link>
            }
            {href &&
                <a target={target} href={href} type="button"
                      className={`${getColor()} inline-flex items-center ${getPadding(size)} border border-transparent text-sm font-medium rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2`}>
                    {icon && getIconFromName(icon) }
                    {text}
                </a>
            }

            {onClick &&
                <button
                    type="button"
                    className={`${getColor()} inline-flex items-center ${getPadding(size)} border border-transparent text-sm font-medium rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 ${disabled ? 'cursor-not-allowed pointer-events-none' : ''}`}
                >
                    {loading && <span className="mr-1 flex items-center"><Loader size="smaller" inline/></span> }
                    {icon && getIconFromName(icon) }
                    {text}
                </button>
            }
        </div>
    )
}
