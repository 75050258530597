import {ArrowUpIcon, CheckCircleIcon, XCircleIcon} from "@heroicons/react/solid";
import Button from "../../atoms/Button";
import MeasureCardProgress from "../../atoms/MeasureCardProgress";
import MeasureRequestButton from "../MeasureRequestButton";
import {Link} from "react-router-dom";

type props = {
    category: any
    measure: any
    userCompleted?: boolean
}

export default function CompletedMeasureCard({measure, category, userCompleted = false}: props): React.ReactElement {
    function getSlug() {
        return '/maatregelen/'+category.slug+'/'+measure.slug;
    }
    return (
        <div className="col-span-1 flex flex-col justify-between relative h-full p-6 bg-white rounded-lg shadow">
            <Link to={getSlug()} className="opacity-[0.7]">
                <MeasureCardProgress current={4}/>
                <div className="mb-6">
                    <h4 className="text-xxl flex items-center font-bold tracking-tight text-gray-900 md:text-2xl">
                        <CheckCircleIcon className="h-8 w-8 mr-2 text-green-500" aria-hidden="true" />
                        {measure.name}</h4>
                </div>
                <div className="grid grid-cols-1 gap-4 mb-6 sm:grid-cols-2">
                    <div>
                        <span className="text-sm block font-medium text-gray-500 truncate">Energiebesparing</span>
                        <div
                            className="inline-flex items-baseline px-2.5 py-0.5 rounded-full text-md font-medium bg-green-100 text-green-800 md:mt-1">

                            <ArrowUpIcon className="-ml-1 mr-0.5 flex-shrink-0 self-center h-5 w-5 text-green-500"/>
                            <span className="sr-only">
                Besparing
              </span>
                            20%
                        </div>
                    </div>


                </div>
            </Link>
            {/*{userCompleted && <ServiceRequestButton service={measure}/> }*/}

        </div>
    )
}
