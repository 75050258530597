import UnavailableMeasureCard from "../../molecules/MeasureCards/UnavailableMeasureCard";
import CompletedMeasureCard from "../../molecules/MeasureCards/CompletedMeasureCard";
import OfferedMeasureCard from "../../molecules/MeasureCards/OfferedMeasureCard";
import AvailableMeasureCard from "../../molecules/MeasureCards/AvailableMeasureCard";
import {Link} from "react-router-dom";
import Data from "../../../utils/Data";
import toast from "react-hot-toast";
import Loader from "../../atoms/Loader";
import RequestedMeasureCard from "../../molecules/MeasureCards/RequestedMeasureCard";
import React, {useEffect, useState} from "react";

type props = {
    category: any
    index?: number
}

export default function MeasureCategory({category, index}: props): React.ReactElement {
    const {data, isLoading, isError } =  Data('/measure-categories/'+category.id+'/measures')
    const [recommended, setRecommended] = useState(null)

    if (isError) toast.error("Kan maatregelen niet laden")

    function getSlug() {
        return '/maatregelen/'+category.slug;
    }

    function getIndex() {
        if(index !== undefined) return index+1;
        return false;
    }

    useEffect(() => {
        if(data) {
            setRecommended(data.findIndex((element : any) => element.status === 'AVAILABLE'));
        }
    }, [data]);


    function getPercentage() {
        if(data) {
            let total = data.length;
            let complete = 0;
            Object.entries(data).forEach(([key, value], index) => {
                // @ts-ignore
                if (value.status === 'COMPLETED') {
                    complete++;
                }
                // @ts-ignore
                if (value.status === 'USER_COMPLETED') {
                    complete++;
                }
                // @ts-ignore
                if (value.status === 'UNAVAILABLE' || value.status === 'REGION_UNAVAILABLE') {
                    total--;
                }

            })
            return complete/total*100;
        }
    }

    return (
        <div id="maatregelen" className={`mb-20 ${getPercentage() === 100 ? 'opacity-80' : ''}`}>
            <div>
                <div className="flex justify-between items-center">
                    <div>
                        {getIndex() &&
                            <span className="text-xs block font-medium text-gray-500 truncate">Stap {getIndex()}</span>
                        }
                        <h2 className="text-2xl mb-3 font-bold tracking-tight text-gray-900 md:text-3xl">{category.name}</h2>
                    </div>

                </div>
                <p className="max-w-xl mb-6">{category.description}</p>
                <div className="h-7 w-full mb-12 relative max-w-xl rounded-md overflow-hidden">
                    <div className="w-full h-full bg-gray-200 absolute"></div>
                    <div id="bar"
                         style={{ width: getPercentage()+'%'}}
                         className="min-w-[40px] transition-all ease-out duration-1000 flex items-center justify-center h-full bg-green-600 text-center text-white text-xs relative w-1/2">{getPercentage()?.toFixed()}%</div>
                </div>
            </div>

            <div className="mb-12 grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4">
                {isLoading ? <Loader/> :
                    <>
                        {data.map((measure: any, index: number) =>
                            <div key={index}>
                                {measure.status === 'AVAILABLE' && <AvailableMeasureCard recommended={recommended === index} category={category} measure={measure}/>}
                                {measure.status === 'UNAVAILABLE' && <UnavailableMeasureCard text="Deze maatregel is (nog) niet mogelijk" measure={measure}/>}
                                {measure.status === 'REQUESTED' && <RequestedMeasureCard category={category} measure={measure}/>}
                                {measure.status === 'OFFERED' && <OfferedMeasureCard category={category} measure={measure}/>}
                                {measure.status === 'COMPLETED' && <CompletedMeasureCard category={category} measure={measure}/>}
                                {measure.status === 'USER_COMPLETED' && <CompletedMeasureCard userCompleted category={category} measure={measure}/>}
                                {measure.status === 'UNKNOWN' && <AvailableMeasureCard unknown category={category} measure={measure}/> }
                                {measure.status === 'DIDNT_QUALIFY' && <UnavailableMeasureCard text="Het is onbekend of deze maatregel mogelijk is voor jouw woning" showButton={true} category={category} measure={measure}/>}
                                {measure.status === 'REGION_UNAVAILABLE' && <UnavailableMeasureCard text="Wij bieden deze maatregel (nog) niet aan in jouw regio." measure={measure}/>}
                            </div>
                        )}
                    </>
                }
            </div>
        </div>
    )
}
