import Breadcrumbs from "../../components/organisms/Breadcrumbs"
import React, {useEffect, useState} from "react";
import Button from "../../components/atoms/Button";
import TextInput from "../../components/atoms/Input/TextInput";
import {UserIcon} from "@heroicons/react/outline";
import Data from "../../utils/Data";
import toast from "react-hot-toast";
import Loader from "../../components/atoms/Loader";
import {AccountMapper} from "../../mappers/Account";
import UpdateData from "../../utils/UpdateData";

export default function MyAccount(): React.ReactElement {
    const {data, isLoading, isError } =  Data('/me')
    const account = AccountMapper(data);

    /* Input fields */
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('')
    const [acceptedMarketing, setAcceptedMarketing] = useState(false)

    const [password, setPassword] = useState('')
    const [newPassword, setNewPassword] = useState('')
    const [newPasswordConfirmation, setNewPasswordConfirmation] = useState('')

    useEffect(() => {
        setFirstName(account.first_name)
        setLastName(account.last_name)
        setEmail(account.email)
        setPhone(account.phone)
        setAcceptedMarketing(!!account.accepted_marketing_at)
    }, [account.first_name, account.last_name, account.email, account.phone, account.title, account.accepted_marketing_at]);


    const [updatePreferencesLoading, setupdatePreferencesLoading]= useState(false)
    const [updateAccountLoading, setupdateAccountLoading]= useState(false)

    if (isError) toast.error("Kan 'mijn account' niet laden")

    const links = [
        { name: 'Mijn account', to: '/mijn-account' },
    ]

    const navigation = [
        { name: 'Algemene gegevens', href: '#algemeen', icon: UserIcon, current: true },
    ]

    function classNames(...classes: any[]) {
        return classes.filter(Boolean).join(' ')
    }

    function updatePreferences() {
        UpdateData('me',
            {
                first_name: account.first_name,
                last_name: account.last_name,
                email: account.email,
                phone: account.phone,
                accepted_marketing: acceptedMarketing,
                title: account.title
            },
            'Voorkeuren zijn bijgewerkt',
            'Kan voorkeuren niet bijwerken',
            setupdatePreferencesLoading)
    }
    function updateAccount() {
        let params = {};
        if(newPassword) {
             params = {
                current_password: password,
                new_password: newPassword,
                new_password_confirmation: newPasswordConfirmation
            }
        }

        UpdateData('me',
            {
                first_name: firstName,
                last_name: lastName,
                email,
                phone,
                accepted_marketing: !!account.accepted_marketing_at,
                ...params
            },
            'Accountgegevens zijn bijgewerkt',
            'Kan accountgegevens niet bijwerken',
            setupdateAccountLoading)
    }

    return (
        <div className="max-w-5xl container mx-auto">
            <Breadcrumbs links={links}/>
            <div className="lg:grid lg:grid-cols-12 lg:gap-x-5">

                <aside className="py-6 px-2 sm:px-6 lg:py-0 lg:px-0 lg:col-span-3">
                    <nav className="space-y-1">
                        {navigation.map((item) => (
                            <a
                                key={item.name}
                                href={item.href}
                                className={classNames(
                                    item.current
                                        ? 'bg-gray-50 text-blue-700 hover:text-blue-700 hover:bg-white'
                                        : 'text-gray-900 hover:text-gray-900 hover:bg-gray-50',
                                    'group rounded-md px-3 py-2 flex items-center text-sm font-medium'
                                )}
                                aria-current={item.current ? 'page' : undefined}
                            >
                                <item.icon
                                    className={classNames(
                                        item.current
                                            ? 'text-blue-500 group-hover:text-blue-500'
                                            : 'text-gray-400 group-hover:text-gray-500',
                                        'flex-shrink-0 -ml-1 mr-3 h-6 w-6'
                                    )}
                                    aria-hidden="true"
                                />
                                <span className="truncate">{item.name}</span>
                            </a>
                        ))}
                    </nav>
                </aside>

                <div className="space-y-6 sm:px-6 lg:px-0 lg:col-span-9">
                    {isLoading ? <Loader/> :
                        <div>
                            <form action="#" id="algemeen" className="mb-8"  method="POST">
                                <div className="shadow sm:rounded-md sm:overflow-hidden">
                                    <div className="bg-white py-6 px-4 space-y-6 sm:p-6">
                                        <div>
                                            <h3 className="text-lg leading-6 font-medium text-gray-900">Mijn account</h3>
                                            <p className="mt-1 text-sm text-gray-500">Dit zijn de algemene gegevens over jouw account zoals e-maildres en wachtwoord.</p>
                                        </div>
                                        <div className="grid grid-cols-6 gap-6">
                                            <div className="col-span-12 mt-4 sm:col-span-3">
                                                <TextInput label="Voornaam" onChange={(e: any) => {
                                                    setFirstName(e.target.value);
                                                }} defaultValue={account.first_name} type="text" required reference="first_name"/>
                                            </div>
                                            <div className="col-span-12 mt-4 sm:col-span-3">
                                                <TextInput onChange={(e: any) => {
                                                    setLastName(e.target.value);
                                                }} label="Achternaam" defaultValue={account.last_name} type="text" required reference="last_name"/>
                                            </div>
                                            <div className="col-span-12 mt-4 sm:col-span-6">
                                                <TextInput onChange={(e: any) => {
                                                    setEmail(e.target.value);
                                                }} label="E-mailadres" defaultValue={account.email}  type="email" required reference="email"/>
                                            </div>
                                            <div className="col-span-12 mt-4 sm:col-span-6">
                                                <TextInput onChange={(e: any) => {
                                                    setPhone(e.target.value);
                                                }} label="Telefoonnummer" defaultValue={account.phone}  type="text" required reference="phone"/>
                                            </div>

                                            <div className="col-span-12 mt-4 sm:col-span-6">
                                                <TextInput onChange={(e: any) => {
                                                    setPassword(e.target.value);
                                                }} label="Huidige wachtwoord" type="password" required reference="password"/>
                                            </div>
                                            <div className="col-span-12 mt-4 sm:col-span-3">
                                                <TextInput onChange={(e: any) => {
                                                    setNewPassword(e.target.value);
                                                }} label="Nieuw wachtwoord" type="password" required reference="newpassword"/>
                                            </div>
                                            <div className="col-span-12 mt-4 sm:col-span-3">
                                                <TextInput onChange={(e: any) => {
                                                    setNewPasswordConfirmation(e.target.value);
                                                }} label="Nieuw wachtwoord nogmaals" type="password" required reference="newpasswordconfirmation"/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="px-4 py-3 bg-gray-50 flex justify-end items-center text-right sm:px-6">
                                        {updateAccountLoading && <Loader inline size="small"/> }
                                        <div className="ml-2">
                                            <Button onClick={() => updateAccount()} color="green" text="Opslaan"/>
                                        </div>
                                    </div>
                                </div>
                            </form>

                            {/*<form action="#" id="voorkeuren" className="mb-8" method="POST">*/}
                            {/*    <div className="shadow sm:rounded-md sm:overflow-hidden">*/}
                            {/*        <div className="bg-white py-6 px-4 space-y-6 sm:p-6">*/}
                            {/*            <div>*/}
                            {/*                <h3 className="text-lg leading-6 font-medium text-gray-900">Mijn voorkeuren</h3>*/}
                            {/*                <p className="mt-1 text-sm text-gray-500">Dit zijn de voorkeuren van uw account</p>*/}
                            {/*            </div>*/}

                            {/*            <fieldset>*/}
                            {/*                <div className="space-y-4">*/}
                            {/*                    <div className="flex items-start">*/}
                            {/*                        <div className="flex items-center h-5">*/}
                            {/*                            <input id="marketing" name="marketing" type="checkbox"*/}
                            {/*                                   onChange={(e: any) => {*/}
                            {/*                                       setAcceptedMarketing(e.target.checked);*/}
                            {/*                                   }}*/}
                            {/*                                   checked={acceptedMarketing}*/}
                            {/*                                   className="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300 rounded"/>*/}
                            {/*                        </div>*/}
                            {/*                        <div className="ml-3 text-sm">*/}
                            {/*                            <label htmlFor="marketing"*/}
                            {/*                                   className="font-medium text-gray-700">Marketing emails*/}
                            {/*                                <p className="text-gray-500">Ontvang persoonlijke aanbiedingen</p></label>*/}
                            {/*                        </div>*/}
                            {/*                    </div>*/}
                            {/*                </div>*/}
                            {/*            </fieldset>*/}
                            {/*        </div>*/}
                            {/*        <div className="px-4 py-3 bg-gray-50 flex justify-end items-center text-right sm:px-6">*/}
                            {/*            {updatePreferencesLoading && <Loader inline size="small"/> }*/}
                            {/*            <div className="ml-2">*/}
                            {/*                <Button onClick={() => updatePreferences()} color="green" text="Opslaan"/>*/}
                            {/*            </div>*/}
                            {/*        </div>*/}
                            {/*    </div>*/}
                            {/*</form>*/}
                        </div>

                    }
                </div>
            </div>
        </div>

    )
}
