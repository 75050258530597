import {CheckIcon} from "@heroicons/react/solid";
import {useEffect, useState} from "react";


type props = {
    measure: any
}

export default function MeasureStatus({measure}: props): React.ReactElement {

    const [steps, setSteps]= useState([
        { name: 'Aanvraag gedaan', description: 'Je hebt deze maatregel aangevraagd',date: '', status: 'upcoming' },
        {
            name: 'Voorlopige offerte',
            description: 'Telefonisch gesprek om situatie te bespreken en inspectie in te plannen.',
            status: 'upcoming',
        },
        { name: 'Offerte opgesteld', description: 'Er is een offerte verzonden.', status: 'upcoming' },
        { name: 'Offerte geaccepteerd', description: 'De offerte is geaccepteerd, maatregel wordt uitgevoerd.', href: '#', status: 'upcoming' },
    ])

    const states = ['AVAILABLE', '', 'REQUESTED', 'OFFERED', 'COMPLETED']

    useEffect(() => {
        const newState = steps.map((obj, index) => {
            if(measure.status === 'COMPLETED') return {...obj, status: 'complete' }
            if (states[index+1] === measure.status) {
                return {...obj, status: 'current' }
            }
            if(index < states.indexOf(measure.status)) {
                return {...obj, status: 'complete' }
            }
            return obj;
        });
        setSteps(newState);
    }, [measure]);

    function classNames(...classes: any) {
        return classes.filter(Boolean).join(' ')
    }

    return (
        <div className="bg-white shadow sm:rounded-lg">
            <div className="px-4 py-5 sm:px-6">
                <h2 id="applicant-information-title" className="text-lg leading-6 font-medium text-gray-900">
                    Status
                </h2>
            </div>
            <div className="border-t border-gray-200 flow-root px-4 py-5  sm:px-6">
                <nav aria-label="Progress">
                    <ol role="list" className="overflow-hidden">
                        {steps.map((step, stepIdx) => (
                            <li key={step.name}
                                className={classNames(stepIdx !== steps.length - 1 ? 'pb-10' : '', 'relative')}>
                                {step.status === 'complete' ? (
                                    <>
                                        {stepIdx !== steps.length - 1 ? (
                                            <div
                                                className="-ml-px absolute mt-0.5 top-4 left-4 w-0.5 h-full bg-blue-600"
                                                aria-hidden="true"/>
                                        ) : null}
                                        <span className="relative flex items-start group">
                              <span className="h-9 flex items-center">
                                <span
                                    className="relative z-10 w-8 h-8 flex items-center justify-center bg-blue-600 rounded-full">
                                  <CheckIcon className="w-5 h-5 text-white" aria-hidden="true"/>
                                </span>
                              </span>
                                            <span className="ml-4 min-w-0 flex flex-col w-full">
                                                                                <div
                                                                                    className="flex w-full justify-between">
                                                                                    <div className="flex flex-col">
                                                                                        <span
                                                                                            className="text-xs font-semibold tracking-wide uppercase">{step.name}</span>
                                <span className="text-sm text-gray-500">{step.description}</span>
                                                                                    </div>
                                                                                    <span
                                                                                        className="text-sm text-gray-500">{step.date}</span>
                                                                                </div>

                              </span>
                                        </span>
                                    </>
                                ) : step.status === 'current' ? (
                                    <>
                                        {stepIdx !== steps.length - 1 ? (
                                            <div
                                                className="-ml-px absolute mt-0.5 top-4 left-4 w-0.5 h-full bg-gray-300"
                                                aria-hidden="true"/>
                                        ) : null}
                                        <span className="relative flex items-start group"
                                           aria-current="step">
                              <span className="h-9 flex items-center" aria-hidden="true">
                                <span
                                    className="relative z-10 w-8 h-8 flex items-center justify-center bg-white border-2 border-blue-600 rounded-full">
                                  <span className="h-2.5 w-2.5 bg-blue-600 rounded-full"/>
                                </span>
                              </span>
                                            <span className="ml-4 min-w-0 flex flex-col">
                                <span
                                    className="text-xs font-semibold tracking-wide uppercase text-blue-600">{step.name}</span>
                                <span className="text-sm text-gray-500">{step.description}</span>
                              </span>
                                        </span>
                                    </>
                                ) : (
                                    <>
                                        {stepIdx !== steps.length - 1 ? (
                                            <div
                                                className="-ml-px absolute mt-0.5 top-4 left-4 w-0.5 h-full bg-gray-300"
                                                aria-hidden="true"/>
                                        ) : null}
                                        <span className="relative flex items-start group">
                              <span className="h-9 flex items-center" aria-hidden="true">
                                <span
                                    className="relative z-10 w-8 h-8 flex items-center justify-center bg-white border-2 border-gray-300 rounded-full group-hover:border-gray-400">
                                  <span className="h-2.5 w-2.5 bg-transparent rounded-full group-hover:bg-gray-300"/>
                                </span>
                              </span>
                                            <span className="ml-4 min-w-0 flex flex-col">
                                <span
                                    className="text-xs font-semibold tracking-wide uppercase text-gray-500">{step.name}</span>
                                <span className="text-sm text-gray-500">{step.description}</span>
                              </span>
                                        </span>
                                    </>
                                )}
                            </li>
                        ))}
                    </ol>
                </nav>
            </div>
        </div>
    )
}
