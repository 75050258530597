import React from "react";
import Loader from "../../../components/atoms/Loader";
export default function Login(): React.ReactElement {

    window.location.replace(process.env.REACT_APP_BACKEND_AUTH_URL + "?redirect_uri=" + window.location.origin + "/auth/login/:token");

    return (
        <div className="w-screen h-screen">
            <Loader/>
        </div>
    )
}
