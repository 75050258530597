import {Link} from "react-router-dom";
import React from "react";
import {CheckIcon} from "@heroicons/react/outline";
import { Fragment, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'

type props = {
    title: string
    description: string
    status?: 'success'
}

export default function Modal({title, description, status = 'success'}: props): React.ReactElement {
    const [open, setOpen] = useState(true)
    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={setOpen}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-xl sm:p-6">
                                <div className="sm:flex sm:items-start">
                                    <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-green-100 sm:mx-0 sm:h-10 sm:w-10">
                                        <CheckIcon className="h-6 w-6 text-green-600" aria-hidden="true" />
                                    </div>
                                    <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                                        <Dialog.Title as="h3" className="text-xl font-medium leading-6 text-gray-900">
                                            {title}
                                        </Dialog.Title>
                                        <div className="mt-2">
                                            <p className="text-base text-gray-600">
                                                {description}
                                            </p>
                                        </div>
                                        <h3 className="text-lg block mt-6 leading-6 mb-1 font-medium text-gray-900">Wat u van ons kunt verwachten:</h3>
                                        <ol className="list-disc list-inside">
                                            <li className="text-base text-gray-600 mb-4">
                                                Digitale woningopname voor een nauwkeurige schatting van de woonsituatie.
                                                We nemen contact op voor het inventariseren van jouw specifieke wensen en aanvullende gegevens van de woning. We maken een afspraak met de adviseur op een datum die jouw het beste uitkomt.</li>
                                            <li className="text-base text-gray-600 mb-4">Opties bespreken voor de gekozen maatregel.
                                                Onze adviseur neemt telefonisch contact op om de situatie door te nemen. In dit gesprek worden alle mogelijkheden besproken, op basis hier van krijg je een passend advies voor de situatie.</li>
                                            <li className="text-base text-gray-600 mb-4">Richtofferte en subsidie.
                                                Op basis van het advies stelt de adviseur een nauwkeurige offerte op. De tweede stap is dat we op locatie langskomen op de situatie specifiek op te meten en te kijken of alles mogelijk is.
                                            </li>
                                        </ol>

                                    </div>
                                </div>
                                <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                                    <Link
                                        type="button"
                                        className="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:text-sm"
                                        to="/"
                                    >
                                        Ga terug naar dashboard
                                    </Link>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}

