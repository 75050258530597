import useSWR from "swr";
import axios from "../lib/axios";

const LoginFetcher = (url, token) => axios.get(url, {
    headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
    }
}).then(res => res)

const Login = (token) => {
    const { data, error } = useSWR(['/me', token], LoginFetcher, {
        revalidateOnFocus: false,
        revalidateOnMount: true,
    })

    return {
        data: data,
        isLoading: !error && !data,
        isError: error
    }
}

export default Login
