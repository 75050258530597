import Axios from 'axios'
import {redirect} from "react-router-dom";

const axios = Axios.create({
    baseURL: process.env.REACT_APP_BACKEND_API_URL+'/api/v1',
})

axios.interceptors.request.use(function (config) {
    const token = localStorage.getItem('authtoken');
    // @ts-ignore
    config.headers.Authorization =  token ? `Bearer ${token}` : '';
    return config;
});

axios.interceptors.response.use(function (response) {
    return response;
}, function (error) {
    if(error.statusCode === '403') {
        redirect('/auth/login')
    }
    return Promise.reject(error);

});
export default axios
