import Button from "../../atoms/Button";
import MeasureCardProgress from "../../atoms/MeasureCardProgress";
import {ConvertDateTime} from "../../../utils/ConvertDateTime";
import moment from "moment";

type props = {
    category: any
    measure: any
}

export default function OfferedMeasureCard({measure, category}: props): React.ReactElement {
    function expiresInDays() {
        const expires = moment(measure.quote.expires_at).diff(moment(), 'days');
        if(expires < 0) {
            return 'Offerte is verlopen'
        } else if(expires < 4) {
            return `Offerte verloopt over <strong className='font-bold' style="color: #ef4444;">${expires} dagen</strong>`
        } else {
            return `Offerte verloopt over <strong className='font-bold'>${expires} dagen</strong>`
        }

    }

    return (
        <a href={measure.quote.url} target="_blank" className="flex h-full justify-between flex-col col-span-1 p-6 bg-white rounded-lg border-2 border-blue-400 border relative " rel="noreferrer">
                        <span className="absolute -top-2.5 -right-2.5 flex h-5 w-5">
  <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-blue-400 opacity-75"></span>
  <span className="relative inline-flex rounded-full h-5 w-5 bg-blue-500"></span>
</span>
            <div className="mb-6">
                <MeasureCardProgress current={3}/>
                <div className="flex items-center">
                    <span className="mr-3 flex-none rounded-lg inline-flex p-1 bg-blue-100 text-teal-700 ring-2 ring-white">
                        <img alt={measure.name} className="h-8 w-8 object-cover" src={measure.image_url}/>
                    </span>
                    <div>
                        <h4 className="text-xxl font-bold tracking-tight text-gray-900 md:text-2xl leading-tight md:leading-tight">{measure.name}</h4>
                        <span className="text-xs font-medium text-gray-500 leading-none" dangerouslySetInnerHTML={{__html: expiresInDays()}}></span>
                    </div>

                </div>
            </div>

            <div className="grid grid-cols-1 gap-4 mb-6 sm:grid-cols-2">
                <div>
                    <span className="text-sm block font-medium text-gray-500 truncate">Datum</span>
                    <span className="text-xl font-semibold text-gray-900">{ConvertDateTime(measure.quote.issued_at, 'DD-MM-YYYY')}</span>
                </div>
                <div>
                    <span className="text-sm block font-medium text-gray-500 truncate">Bedrag</span>
                    <span className="text-xl font-semibold text-gray-900">€{measure.quote.total_price}</span>
                </div>
            </div>
            <Button target="_blank" href={measure.quote.url} color="green" text="Bekijk offerte"/>
        </a>
    )
}
