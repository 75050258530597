import Button from "../../atoms/Button";
import axios from "../../../lib/axios";
import {BuildingMapper} from "../../../mappers/Building";
import toast from "react-hot-toast";
import Loader from "../../atoms/Loader";
import {useState} from "react";
import {useNavigate} from "react-router-dom";

type props = {
    measure?: any
    customUrl?: string
    text?: string
    category?: any
    color?: string
    disabled?: boolean
}

export default function MeasureRequestButton({disabled, measure, category, customUrl = '', text = 'Vrijblijvende offerte aanvragen', color = 'blue'} : props): React.ReactElement {
    const [loading, setLoading] = useState(false);
    const [disableButton, setDisableButton] = useState(false);
    const navigate = useNavigate();
    const getRequestUrl = () => {
        setLoading(true);

        const redirectToUrl = (url: string) => {
            window.location.href = url;
        }
        if(disabled) {
            setDisableButton(true)
        }

        axios.post(customUrl ? customUrl : '/measures/'+measure.slug+'/request')
            .then(function (response) {
                setLoading(false);
                if(response.data.data.type === 'redirect') {
                    setDisableButton(true)
                    toast.success('Je wordt doorverwezen om de aanvraag te voltooien')
                    redirectToUrl(response.data.data.url);
                } else if(response.data.data.type === 'completed') {
                    setDisableButton(true)
                    if(!customUrl) {
                        toast.success('Maatregel is aangevraagd!')
                        navigate('/maatregelen/'+category.slug+'/'+measure.slug+'/aangevraagd');
                    } else {
                        toast.success('Advies is aangevraagd!')
                    }
                } else {
                    toast.error('Deze maatregel kan nog niet aangevraagd worden')
                }
            })
            .catch(function (error) {
                setDisableButton(true)
                setLoading(false);
                if(error.response.data.message) {
                    toast.error(error.response.data.message)
                } else {
                    toast.error('Maatregel kon niet worden aangevraagd')
                }
            });
    }

    return (
        <div className="inline-flex items-center">
            <Button disabled={disableButton} onClick={() => getRequestUrl()} loading={loading} color={color} text={text}/>
        </div>
    )
}
