import MeasureRequestButton from "../MeasureRequestButton";

type props = {
    title: string
    description: string

}

export default function AdviceCard({title, description}: props): React.ReactElement {
    return (
        <div className="col-span-1 p-8 bg-white rounded-lg shadow">
                <div>
                   <div className="flex items-center mb-6">
                        <span className="mr-4 h-auto block rounded-lg inline-flex p-4 px-6 font-bold text-blue-500 text-3xl bg-blue-100 ring-2 ring-white">
                        ?
                    </span>
                       <h2 className="text-3xl font-bold  tracking-tight text-gray-900 md:text-3xl">{title}</h2>
                   </div>
                    <p className="text-gray-600 text-lg mb-6">{description}</p>
                    <MeasureRequestButton text="Uitgebreid advies aanvragen" customUrl="/advice/request"/>
                </div>
        </div>
    )
}
