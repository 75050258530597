import {Link} from "react-router-dom";
import {CashIcon, ChartBarIcon} from "@heroicons/react/solid";
import MeasureRequestButton from "../MeasureRequestButton";
import Tooltip from "../../atoms/Tooltip";
import Button from "../../atoms/Button";

type props = {
    measure: any
}

export default function MeasureSavings({measure}: props): React.ReactElement {
    const returnInvestment = () => {
        const investment = measure.building.total_investment-(measure.subsidy.per_unit*measure.building.quantity);
        return Math.ceil(investment / measure.calculations.estimated_yearly_savings);
    }

    return (
        <section aria-labelledby="applicant-information-title">
            <div className="bg-white shadow sm:rounded-lg">
                <div className="px-4 py-5 sm:px-6 flex items-center justify-between">
                    <div>
                        <h2 id="applicant-information-title" className="text-lg leading-6 font-medium text-gray-900">
                            Indicatie voor jouw woning
                        </h2>
                        {measure.calculations.estimated_yearly_savings && returnInvestment() < 11 &&
                            <div className="mt-1">
                                <Tooltip text="Inschatting op basis van bij ons bekende gegevens">
                                    <p className="max-w-2xl text-sm text-gray-500">Deze maatregel heb je waarschijnlijk binnen +/- {returnInvestment()} jaar terugverdiend</p>
                                </Tooltip>
                            </div>
                        }
                    </div>
                    <Button text="Woninggegevens wijzigen" to="/mijn-woning#meta" color="gray"/>
                </div>
                <div className="border-t border-b border-gray-200 px-4 pt-5 sm:px-6">
                    <div className="mt-4 mb-2 grid grid-cols-1 gap-5 sm:grid-cols-3">
                        <div>
                            <div>
                                <div className="absolute bg-blue-500 rounded-md p-3">
                                    <CashIcon className="h-6 w-6 text-white"/>
                                </div>
                                <p className="ml-16 text-sm font-medium text-gray-500 truncate">Verwachte
                                    investering</p>
                            </div>
                            <div className="ml-16 pb-6 flex items-baseline sm:pb-7">
                                <p className="text-2xl font-semibold text-gray-900">
                                    &euro; {Math.round(measure.building.total_investment)}
                                </p>
                            </div>
                        </div>
                        {(measure.subsidy.per_unit !== '0.00' && measure.subsidy.per_unit !== 0) &&
                            <div>
                                <div>
                                    <div className="absolute bg-green-500 rounded-md p-3">
                                        <CashIcon className="h-6 w-6 text-white"/>
                                    </div>
                                    <p className="ml-16 text-sm font-medium text-gray-500 truncate">Mogelijke subsidie</p>
                                </div>
                                <div className="ml-16 pb-6 flex items-baseline sm:pb-7">
                                    <p className="text-2xl font-semibold text-gray-900">
                                        &euro; {measure.subsidy.per_unit*measure.building.quantity}
                                    </p>
                                </div>
                            </div>
                        }
                        {measure.calculations.estimated_yearly_savings &&
                            <div>
                                <div>
                                    <div className="absolute bg-green-500 rounded-md p-3">
                                        <ChartBarIcon className="h-6 w-6 text-white"/>
                                    </div>
                                    <p className="ml-16 text-sm font-medium text-gray-500 truncate">Jaarlijkse besparing</p>
                                </div>
                                <div className="ml-16 pb-6 flex items-baseline sm:pb-7">
                                    <p className="text-2xl font-semibold  text-gray-900">
                                        &euro; {Math.floor(measure.calculations.estimated_yearly_savings)}
                                    </p>
                                </div>
                            </div>
                        }
                    </div>

                </div>
                <div className="px-4 py-5 sm:px-6">
                    {measure.status === 'AVAILABLE' &&
                        <MeasureRequestButton category={measure.category} measure={measure}/>
                    }
                </div>
            </div>
        </section>
    )
}
