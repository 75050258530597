import React from "react";
import {InformationCircleIcon} from "@heroicons/react/solid";

type props = {
    children: React.ReactNode;
    text: string;
};

export default function Tooltip({children, text} : props): React.ReactElement {
    return (
        <span className="group relative items-center inline-flex">
      <span className="sm:block hidden pointer-events-none absolute -top-10 right-2.5 translate-x-1/2 whitespace-nowrap rounded bg-gray-900 px-3 py-1.5 text-white opacity-0 transition before:absolute before:left-1/2 before:top-full text-sm before:-translate-x-1/2 before:border-4 before:border-transparent before:border-t-gray-900 before:content-[''] group-hover:opacity-100">
        {text}
      </span>
            {children}
            <InformationCircleIcon className="ml-0.5 sm:block hidden text-blue-500 w-5 h-5"/>
    </span>
    )
}
