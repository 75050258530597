import axios from "../../../lib/axios";
import toast from "react-hot-toast";
import {useEffect, useState} from "react";
import Loader from "../../atoms/Loader";

type props = {
    measure: any
}

export default function MeasureToggleStatus({measure}: props): React.ReactElement {
    const [loading, setLoading] = useState(false);

    const [checked, setChecked] = useState(false);

    useEffect(() => {
        if(measure.status === 'USER_COMPLETED') setChecked(true)
    }, [measure]);

    const toggleMeasureStatus = (e: any) => {
        setChecked(e)
        setLoading(true)
        axios.post('/measures/'+measure.slug+'/toggle-completion')
            .then(function (response) {
                setLoading(false);
                toast.success('Maatregel status is aangepast')
            })
            .catch(function (error) {
                setChecked(!e)
                setLoading(false);
                if(error.response.data.message) {
                    toast.error(error.response.data.message)
                } else {
                    toast.error('De status van deze maatregel kan niet worden aangepast')
                }
            });
    }

    return (
        <div className="bg-white shadow sm:rounded-lg mb-8">

            <div className="px-4 py-5 sm:px-6 flex justify-between items-center">
                <h2 className="text-lg leading-6 font-medium text-gray-900">
                    Status maatregel
                </h2>
                {loading && <span className="ml-2 flex items-center"><Loader size="smaller" inline/></span> }
            </div>
            <div className="border-t border-gray-200 px-4 pt-3 pb-5 sm:px-6">
                <label className="inline-flex relative items-center cursor-pointer">
                    <input onChange={(e) => toggleMeasureStatus(e.target.checked)} type="checkbox" value="status" checked={checked} className="sr-only peer"/>
                    <div
                        className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-transparent dark:peer-focus:ring-green-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-green-600"></div>
                    <span
                        className="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300">Maatregel reeds toegepast</span>
                </label>

            </div>
        </div>
    )
}
