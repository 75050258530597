import Button from "../../atoms/Button";

type props = {
    title: string
    description: string
    linkText?: string
    linkUrl?: string
    linkTarget?: string
    linkColor?: 'blue' | 'gray'
}

export default function ContentCard({title, description, linkText, linkUrl, linkTarget, linkColor = 'blue'}: props): React.ReactElement {
    return (
        <div className="col-span-1 p-8 bg-white rounded-lg shadow">
                <div>
                    <h2 className="text-3xl font-bold mb-6 tracking-tight text-gray-900 md:text-3xl">{title}</h2>
                    <p className="text-gray-600 text-lg mb-6">{description}</p>
                    <>
                        {linkText && <Button href={linkUrl} target={linkTarget} color={linkColor} text={linkText}/>}
                    </>
                </div>
        </div>
    )
}
