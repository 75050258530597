import {CheckCircleIcon, ExclamationIcon, InformationCircleIcon} from '@heroicons/react/solid'

type props = {
    title: string
    description?: string
    type: 'warning' | 'error' | 'success' | 'info'
}

export default function Message({title, description, type}: props): React.ReactElement {

    let color = ''
    switch(type) {
        case 'warning':
            color = 'yellow';
            break;
        case "error":
            color = 'red';
            break;
        case "success":
            color = 'green'
            break;
        case "info":
            color = 'blue'
            break;
    }

    return (
        <div className={`rounded-md bg-${color}-100 p-4`}>
            <div className="flex">
                <div className="flex-shrink-0">
                    {type === 'warning' && <ExclamationIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />}
                    {type === 'success' && <CheckCircleIcon className="h-5 w-5 text-green-400" aria-hidden="true" />}
                    {type === 'error' && <CheckCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />}
                    {type === 'info' && <InformationCircleIcon className="h-5 w-5 text-blue-400" aria-hidden="true" />}
                </div>
                <div className="ml-3">
                    <h3 className={`text-sm font-medium text-${color}-800`}>{title}</h3>
                    {description && <div className={`mt-2 text-sm text-${color}-700`}>
                        <p>{description}</p>
                    </div>}
                </div>
            </div>
        </div>
    )
}

