
import MeasureCategory from "../../components/organisms/MeasureCategory";
import Breadcrumbs from "../../components/organisms/Breadcrumbs";
import {useParams} from "react-router-dom";
import Data from "../../utils/Data";
import toast from "react-hot-toast";
import Loader from "../../components/atoms/Loader";
import React from "react";
import measureCategory from "../../components/organisms/MeasureCategory";

type props = {
    showAll?: boolean
}

export default function Measures({showAll}: props): React.ReactElement {
    let { measurecategory } = useParams();

    const links = [
        { name: 'Alle maatregelen', to: '/maatregelen' },

    ];

    let url = '/measure-categories';

    if(!showAll) {
        url = '/measure-categories/' + measurecategory
    }

    const {data, isLoading, isError } =  Data(url)
    if (isError) toast.error("Kan categorieën niet laden")

    if(measurecategory) {
        links.push({ name: measurecategory, to: '/maatregelen/'+measurecategory })
    }
    return (
        <div className="max-w-8xl px-4 container mx-auto">
            <Breadcrumbs links={links}/>
            <div className="mb-40">
                {isLoading ? <Loader/> :
                    <>
                        {data.map((category: any, index: number) => (
                            <MeasureCategory key={index} index={index} category={category}/>
                        ))}
                    </>
                }
            </div>
        </div>
    )
}
