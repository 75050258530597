import axios from "../lib/axios";
import toast from "react-hot-toast";

export default function UpdateData(url, params, successMessage, errorMessage, loading, updateData) {
    loading(true)
    axios.put(url, params)
        .then(function (response) {
            toast.success(successMessage)
            if(updateData) {
                updateData(response.data.data)
            }
            loading(false)
        })
        .catch(function (error) {
            if(error.response.data.message) {
                toast.error(error.response.data.message)
            } else {
                toast.error(errorMessage)
            }

            loading(false)
        });
}
