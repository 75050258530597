import {ConvertDateTime} from "../utils/ConvertDateTime";

type AccountType = {
    id: string
    accepted_marketing_at?: string
    accepted_terms_at?: string
    created_at?: string
    email: string
    email_verified_at?: string
    first_name: string
    last_name: string
    title: string
    phone: string
    finished_onboarding_at: string
}

export function AccountMapper(data: any): AccountType {
    return {
        ...data,
        email_verified_at: ConvertDateTime(data?.email_verified_at),
        created_at: ConvertDateTime(data?.created_at),
        accepted_marketing_at: ConvertDateTime(data?.accepted_marketing_at),
        accepted_terms_at: ConvertDateTime(data?.accepted_terms_at)
    }
}

