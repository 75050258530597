import { Fragment } from 'react'
import { Disclosure, Menu, Transition } from '@headlessui/react'
// @ts-ignore
import { MenuIcon, XIcon } from '@heroicons/react/outline'
import {NavLink } from "react-router-dom";
import Data from "../../../utils/Data";
import NavLogo from "../../atoms/NavLogo";
import {UserIcon} from "@heroicons/react/solid";
import Banner from "../../molecules/Banner";

export default function Nav(): React.ReactElement {

    const { data, isLoading } =  Data('/me/brand')

    const {data: invoiceData} = Data('/quotes/unread')


    if(data) {
        localStorage.setItem('energyData', JSON.stringify(data?.configuration));
    }

    const Logout = () => {
        const token = localStorage.getItem('authtoken');
        localStorage.setItem('authtoken', '');
        window.location.replace(process.env.REACT_APP_BACKEND_API_URL + "/logout/?token-"+token);
    }
    return (
        <div>
            <Disclosure as="nav" className="bg-white shadow">
                {({ open }) => (
                    <>
                        <div className="max-w-9xl mx-auto px-4 sm:px-6 lg:px-8">
                            <div className="flex justify-between h-20">
                                <div className="flex">
                                    <NavLink to="/" className="flex-shrink-0 flex items-center">
                                        {isLoading ?
                                            <NavLogo loading/> : <NavLogo logoUrl={data.logo_url} name={data.name}/>
                                        }
                                    </NavLink>
                                    <div className="hidden sm:ml-6 sm:flex sm:space-x-8">
                                        {/* Current: "border-emerald-500 text-gray-900", Default: "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700" */}
                                        <NavLink
                                            to="/"
                                            className={({ isActive }) => (isActive ? 'border-emerald-500 text-gray-900' : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700')+' inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium'}

                                        >
                                            Mijn overzicht
                                        </NavLink>

                                        <NavLink
                                            to="/offertes"
                                            className={({isActive}) => (isActive ? 'border-emerald-500 text-gray-900' : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700') + ' inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium relative'}
                                        >
                                            Offertes
                                            {!!invoiceData?.length &&
                                                <span
                                                    className="absolute w-4 h-4 inline-flex justify-center items-center -right-3 top-1/2 -mt-3 bg-blue-500 text-white rounded-full text-xs">{invoiceData.length}</span>
                                            }
                                        </NavLink>
                                        <NavLink
                                            to="/maatregelen"
                                            className={({ isActive }) => (isActive ? 'border-emerald-500 text-gray-900' : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700')+' inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium'}

                                        >
                                            Duurzame maatregelen
                                        </NavLink>
                                        <NavLink
                                            to="/mijn-woning"
                                            className={({ isActive }) => (isActive ? 'border-emerald-500 text-gray-900' : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700')+' inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium'}
                                        >
                                            Mijn woning
                                        </NavLink>
                                        <NavLink
                                            to="/veelgestelde-vragen"
                                            className={({ isActive }) => (isActive ? 'border-emerald-500 text-gray-900' : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700')+' inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium'}
                                        >
                                            Veelgestelde vragen
                                        </NavLink>
                                    </div>
                                </div>
                                <div className="hidden sm:ml-6 sm:flex sm:items-center">

                                    {/* Profile dropdown */}
                                    <Menu as="div" className="ml-3 relative">
                                        <div>
                                            <Menu.Button className="bg-white rounded-full flex text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
                                                <span className="sr-only">Menu openen</span>
                                                <div className="bg-blue-500 w-8 h-8 p-2 rounded-full">
                                                    <UserIcon className="fill-white"/>
                                                </div>
                                            </Menu.Button>
                                        </div>
                                        <Transition
                                            as={Fragment}
                                            enter="transition ease-out duration-200"
                                            enterFrom="transform opacity-0 scale-95"
                                            enterTo="transform opacity-100 scale-100"
                                            leave="transition ease-in duration-75"
                                            leaveFrom="transform opacity-100 scale-100"
                                            leaveTo="transform opacity-0 scale-95"
                                        >
                                            <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                                                <Menu.Item>
                                                    <NavLink to="/mijn-account"
                                                             className={({ isActive }) => (isActive ? 'bg-gray-100' : 'text-gray-700')+' block px-4 py-2 text-sm'}>Mijn account</NavLink>
                                                </Menu.Item>
                                                <Menu.Item>
                                                    <NavLink to="/mijn-woning"
                                                             className={({ isActive }) => (isActive ? 'bg-gray-100' : 'text-gray-700')+' block px-4 py-2 text-sm'}>Mijn woning</NavLink>
                                                </Menu.Item>
                                                <Menu.Item>
                                                    <button onClick={() => Logout()}
                                                            className="block px-4 py-2 text-sm">Uitloggen</button>
                                                </Menu.Item>
                                            </Menu.Items>
                                        </Transition>
                                    </Menu>
                                </div>
                                <div className="-mr-2 flex items-center sm:hidden">
                                    {/* Mobile menu button */}
                                    <Disclosure.Button className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-emerald-500">
                                        <span className="sr-only">Menu openen</span>
                                        {open ? (
                                            <XIcon className="block h-6 w-6" aria-hidden="true" />
                                        ) : (
                                            <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                                        )}
                                    </Disclosure.Button>
                                </div>
                            </div>
                        </div>

                        <Disclosure.Panel className="sm:hidden">
                            <div className="pt-2 pb-3 space-y-1">
                                {/* Current: "", Default: "" */}
                                <NavLink
                                    to="/"
                                    className={({ isActive }) => (isActive ? 'bg-emerald-50 border-emerald-500 text-emerald-700' : 'border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700')+' block pl-3 pr-4 py-2 border-l-4 text-base font-medium'}
                                >
                                    Mijn overzicht
                                </NavLink>
                                <NavLink
                                    to="/offertes"
                                    className={({ isActive }) => (isActive ? 'bg-emerald-50 border-emerald-500 text-emerald-700' : 'border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700')+' block pl-3 pr-4 py-2 border-l-4 text-base font-medium'}
                                >
                                    Offertes
                                </NavLink>
                                <NavLink
                                    to="/mijn-woning"
                                    className={({ isActive }) => (isActive ? 'bg-emerald-50 border-emerald-500 text-emerald-700' : 'border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700')+' block pl-3 pr-4 py-2 border-l-4 text-base font-medium'}
                                >
                                    Mijn woning
                                </NavLink>
                                <NavLink
                                    to="/veelgestelde-vragen"
                                    className={({ isActive }) => (isActive ? 'bg-emerald-50 border-emerald-500 text-emerald-700' : 'border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700')+' block pl-3 pr-4 py-2 border-l-4 text-base font-medium'}
                                >
                                    Veelgestelde vragen
                                </NavLink>
                            </div>
                            <div className="pt-4 pb-3 border-t border-gray-200">
                                <div className="space-y-1">
                                    <Disclosure.Button
                                        as="a"
                                        href="#"
                                        className="block px-4 py-2 text-base font-medium text-gray-500 hover:text-gray-800 hover:bg-gray-100"
                                    >
                                        Mijn account
                                    </Disclosure.Button>
                                    <Disclosure.Button
                                        as="a"
                                        href="#"
                                        className="block px-4 py-2 text-base font-medium text-gray-500 hover:text-gray-800 hover:bg-gray-100"
                                    >
                                        Mijn woning
                                    </Disclosure.Button>
                                    <Disclosure.Button
                                        as="a"
                                        href="#"
                                        className="block px-4 py-2 text-base font-medium text-gray-500 hover:text-gray-800 hover:bg-gray-100"
                                    >
                                        Uitloggen
                                    </Disclosure.Button>
                                </div>
                            </div>
                        </Disclosure.Panel>
                    </>
                )}
            </Disclosure>
            {!!invoiceData?.length &&
                <>
                {(invoiceData.length === 1) ?
                    (<Banner text="Er staat een offerte voor je klaar!" target="_blank" color="green" shortText="Er staat een offerte voor je klaar!" buttonText="Bekijk offerte" buttonLink={invoiceData[0].url}/>) :
                    (<Banner text="Er staan meerdere offertes voor je klaar!" color="green" shortText="Er staan meerdere offertes voor je klaar!" buttonText="Bekijk offertes" buttonLink="/offertes"/>)
                }
                </>
                }
        </div>

    )
}
