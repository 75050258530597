import React, {useEffect} from "react";
import {useParams, useNavigate, Outlet, Navigate} from "react-router-dom";
import Login from "../../../utils/Login";
import LoginPage from "../Login";
import Loader from "../../../components/atoms/Loader";

export default function VerifyLogin(): React.ReactElement {
    const { token } = useParams();
    localStorage.setItem('authtoken', token+'')
    const { data, isLoading } = Login(token);

    return (
            <div>
                { isLoading ? <Loader/> :
                    <div>
                        {data ? <Navigate to="/"/> : <Navigate to="/auth/login"/>}
                    </div>
                }
            </div>

    )
}
