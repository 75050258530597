import Button from "../../atoms/Button";
import {Link} from "react-router-dom";
import MeasureCardProgress from "../../atoms/MeasureCardProgress";

type props = {
    category: any
    measure: any
    unknown?: boolean
}

export default function RequestedMeasureCard({measure, category, unknown = false}: props): React.ReactElement {
    function getSlug() {
        if(!unknown) return '/maatregelen/'+category.slug+'/'+measure.slug;
        return ''
    }

    return (
        <Link to={getSlug()} className="flex justify-between h-full flex-col col-span-1 shadow p-6 bg-white rounded-lg relative">
            <div className="mb-6">
                <MeasureCardProgress current={2}/>
                <div className="flex items-center">
                    <span className="mr-3 flex-none rounded-lg inline-flex p-1 bg-blue-100 text-teal-700 ring-2 ring-white">
                        <img alt={measure.name} className="h-8 w-8 object-cover" src={measure.image_url}/>
                    </span>
                    <div>
                        <h4 className="text-xxl font-bold tracking-tight text-gray-900 md:text-2xl leading-tight md:leading-tight">{measure.name}</h4>
                        <span className="text-xs font-medium text-gray-500 leading-none">Wij bellen je binnenkort om een afspraak in te plannen.</span>
                    </div>

                </div>
            </div>
            <div className="grid grid-cols-1 gap-4 mb-6 sm:grid-cols-2">
                {/*<div>*/}
                {/*    <span className="text-sm block font-medium text-gray-500 truncate">Aangevraagd</span>*/}
                {/*    <span className="text-xl font-semibold text-gray-900">14-02-2022</span>*/}
                {/*</div>*/}
                {/*<div>*/}
                {/*    <span className="text-sm block font-medium text-gray-500 truncate">Inspectie</span>*/}
                {/*    <span className="text-xl font-semibold text-gray-900">20-03-2022</span>*/}
                {/*</div>*/}
            </div>
            <Button to={getSlug()} text="Bekijk de aanvaag"/>
        </Link>
    )
}
