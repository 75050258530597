import Loader from "../Loader";

type props = {
    name?: string
    logoUrl?: string
    loading?: boolean
}

export default function NavLogo({logoUrl, name, loading} : props): React.ReactElement {
    return (
        <>
            {loading ?
                <div className="relative lg:h-12 h-10 w-[128px] lg:w-[154px]">
                    <div className="absolute top-1/2 left-1/2 translate -translate-x-1/2 -translate-y-1/2">
                        <Loader size="small"/>
                    </div>
                </div>
                :
                <div>
                    <img
                        className="block lg:hidden h-10 w-auto"
                        src={logoUrl}
                        alt={name}
                    />
                    <img
                        className="hidden lg:block h-12 w-auto"
                        src={logoUrl}
                        alt={name}
                    />
                </div>
            }
        </>


    )
}




