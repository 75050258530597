import Button from "../../atoms/Button";
import React, {useEffect, useState} from "react";
import Data from "../../../utils/Data";
import {BuildingMapper} from "../../../mappers/Building";
import toast from "react-hot-toast";
import Loader from "../../atoms/Loader";
import GeneralBuildingForm from "../Forms/Building/GeneralBuildingForm";
import {CheckIcon} from "@heroicons/react/solid";
import UpdateData from "../../../utils/UpdateData";
import EnergyBuildingForm from "../Forms/Building/EnergyBuildingForm";

type props = {
    account: any
}

export default function Onboarding({account}: props): React.ReactElement {
    const {data, isLoading, isError } =  Data('/me/building')
    const {data: measures, isLoading: measuresIsLoading } =  Data('/measures')

    const [allMeasures, setAllMeasures]= useState<any[]>([])

    useEffect(() => {
        if(measures) {
            setAllMeasures(measures)
        }

    }, [measures]);

    if (isError) toast.error("Kan maatregelen niet laden")


    const [steps, setSteps]= useState([
        { id: 1, name: 'Account aanmaken', status: 'complete' },
        { id: 2, name: 'E-mailadres verifiëren', status: 'complete' },
        {
            id: 3,
            name: 'Woninggegevens aanvullen',
            description: 'We hebben nog een aantal aanvullende gegevens nodig om onder andere de besparingen per maatregel voor je te kunnen berekenen.',
            status: 'current',
        },
        {
            id: 4,
            name: 'Energieverbruik',
            description: 'Om een realistische schatting te maken van de besparing bij het aanbrengen van een duurzame maatregel hebben we je energieverbruik nodig.',
            status: 'upcomming',
        },
        { id: 5, name: 'Zijn er na de bouw van de woning duurzame maatregelen toegepast in de woning? ', status: 'upcoming' },
        { id: 6, name: 'Eerste maatregel aanvragen', description: 'Je profiel is compleet! Je kunt nu een duurzame maatregel aanvragen.', status: 'upcoming' },
    ])

    let building = BuildingMapper(data)

    if (isError) toast.error("Kan 'mijn woning' niet laden")

    const toggleMeasure = (measureId: number, active: boolean) => {
        // @ts-ignore
        const newState = allMeasures.map((obj: any) => {
            if (obj.id === measureId) {
                return {...obj, active: active }
            }
            return obj;
        });
        setAllMeasures(newState);
    }

    function classNames(...classes: any) {
        return classes.filter(Boolean).join(' ')
    }

    const toStepFour = () => {
        const newState = steps.map((obj, index) => {
            if (index === 2) {
                return {...obj, status: 'complete'};
            }
            if (index === 3) {
                return {...obj, status: 'current'};
            }
            return obj;
        });
        setSteps(newState);
    }

    const toStepFive = () => {
        const newState = steps.map((obj, index) => {
            if (index === 3) {
                return {...obj, status: 'complete'};
            }
            if (index === 4) {
                return {...obj, status: 'current'};
            }
            return obj;
        });
        setSteps(newState);
    }

    const lastStepDone = (data: any) => {
        if(data) {
            const newState = steps.map((obj, index) => {
                if (index === 4) {
                    return {...obj, status: 'complete'};
                }
                if (index === 5) {
                    return {...obj, status: 'current'};
                }
                return obj;
            });
            setSteps(newState);
            // @ts-ignore
            document.getElementById('maatregelen').scrollIntoView({behavior: "smooth"});
        }
    }


    const saveMeasures = (empty: boolean) => {
        if(empty) {
            UpdateData('/measures/bulk-update-completed', {measures: allMeasures}, 'Maatregelen zijn bijgewerkt', 'Kan maatregelen niet opslaan', lastStepDone)
        } else {
            UpdateData('/measures/bulk-update-completed', {measures: []}, 'Maatregelen zijn bijgewerkt', 'Kan maatregelen niet opslaan', lastStepDone)
        }

    }

    const energyUsage = () => {
        if(building.gas_usage || building.electricity_usage) return true;
        return false;
    }

    return (
        <>
            {account.email_verified_at &&
                <nav aria-label="Progress" className="mt-10 block">
                    <ol role="list" className="pb-2">
                        {steps.map((step, stepIdx) => (

                            <li key={step.id} className={classNames(stepIdx !== steps.length - 1 ? 'pb-5' : '', 'relative')}>
                                {step.status === 'complete' ? (
                                    <>
                                        {stepIdx !== steps.length - 1 ? (
                                            <div className="-ml-px absolute mt-0.5 top-4 left-4 w-0.5 h-full bg-blue-600" aria-hidden="true" />
                                        ) : null}
                                        <div className="relative flex items-center group">
                  <span className="h-9 flex items-center">
                    <span className="relative text-white text-sm z-10 w-8 h-8 flex items-center justify-center bg-blue-600 rounded-full">
                      {stepIdx+1}
                    </span>
                  </span>
                                            <span className="ml-6 min-w-0 flex items-center flex-col">
                    <span className="text-sm text-gray-500 font-semibold tracking-wide line-through	">{step.name}</span>
                  </span>
                                        </div>
                                    </>
                                ) : step.status === 'current' ? (
                                    <>
                                        {stepIdx !== steps.length - 1 ? (
                                            <div className="-ml-px absolute mt-0.5 top-4 left-4 w-0.5 h-full bg-gray-300" aria-hidden="true" />
                                        ) : null}
                                        <div className="-ml-px absolute mt-0.5 top-4 left-4 w-0.5 h-auto bg-blue-600" aria-hidden="true" />
                                        <div className="relative flex items-start group" aria-current="step">
                  <span className="h-9 flex items-center" aria-hidden="true">

                    <span className="relative z-10 w-8 h-8 flex items-center justify-center bg-white border-2 border-blue-600 rounded-full">
                      <span className="h-2.5 w-2.5 bg-blue-600 rounded-full" />
                    </span>
                  </span>
                                            <span className="ml-6 min-w-0 flex flex-col">
                    <span className="text-lg mt-1 font-semibold tracking-wide mb-1 text-blue-600">{step.name}</span>
                    <span className="text-base text-gray-600 mb-0">{step.description}</span>
                                                {stepIdx === 2 &&
                                                    <>
                                                        {isLoading ? <Loader/> :
                                                            <>
                                                                {building.type && toStepFour()}
                                                                <GeneralBuildingForm nextStep={toStepFour} building={building} onboarding/>
                                                            </>
                                                        }
                                                    </>
                                                }
                                                {stepIdx === 3 &&
                                                    <>
                                                        {isLoading ? <Loader/> :
                                                            <>
                                                                {energyUsage() && toStepFive()}
                                                                <EnergyBuildingForm nextStep={toStepFive} building={building} onboarding/>
                                                            </>
                                                        }
                                                    </>
                                                }
                                                {stepIdx === 4 &&
                                                    <>
                                                        {measuresIsLoading ? <div className="mt-4"><Loader/></div> :
                                                            <>
                                                                {allMeasures &&
                                                                    <>
                                                                        <div className="mt-4 grid grid-cols-1 gap-y-6 grid-cols-1 md:grid-cols-2 2xl:grid-cols-3 sm:gap-x-4">
                                                                            {allMeasures.map((measure: any) => (
                                                                                <button key={measure.id} onClick={(e) => toggleMeasure(measure.id, !measure.active)} className={`${measure.active ? 'border-transparent' : 'border-gray-300'} relative bg-white border text-left rounded-lg items-center shadow-sm p-4 flex cursor-pointer focus:outline-none`}>
                                                        <span className="flex-1 flex">
                                                            <span className="flex items-top flex-col">
                                                              <span id="project-type-0-label" className="mr-2 block text-sm font-medium text-gray-900">
                                                                {measure.name}
                                                              </span>
                                                            </span>
                                                          </span>
                                                                                    <span className={`w-4 h-4 block border inline-flex items-center justify-center rounded-full ${measure.active ? 'border-blue-500 bg-blue-500' : 'border-gray-300'}`}>
                                                            {measure.active && <CheckIcon className="fill-white h-3 w-3"/> }
                                                        </span>
                                                                                    {measure.active &&  <span className="absolute -inset-px rounded-lg border-2 pointer-events-none border-blue-500" aria-hidden="true"></span> }
                                                                                </button>
                                                                            ))}
                                                                        </div>
                                                                        <span className="text-gray-400 text-sm mt-3">Twijfel je of een maatregel aanwezig is of is deze maar deels aanwezig? Vink deze dan <strong className="font-bold">niet</strong> aan.</span>
                                                                        <div className="flex align-center justify-end mt-6">
                                                                            <span className="mr-2 text-gray-700 mt-2 text-sm cursor-pointer underline hover:no-underline" onClick={() => saveMeasures(false)}>Nee, geen maatregelen toegepast</span>

                                                                            <Button text="Opslaan" onClick={() => saveMeasures(true)} color="green"/>
                                                                        </div>
                                                                    </>
                                                                }
                                                            </>
                                                        }
                                                    </>
                                                }
                  </span>
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        {stepIdx !== steps.length - 1 ? (
                                            <div className="-ml-px absolute mt-0.5 top-4 left-4 w-0.5 h-full bg-gray-300" aria-hidden="true" />
                                        ) : null}
                                        <div className="relative flex items-center group">
                  <span className="h-9 flex items-center" aria-hidden="true">
                    <span className="relative z-10 w-8 h-8 flex items-center justify-center bg-white border-2 border-gray-300 rounded-full">
                      <span className="h-2.5 w-2.5 bg-transparent inline-flex text-sm text-gray-500 items-center justify-center rounded-full">
                          {stepIdx+1}
                      </span>
                    </span>
                  </span>
                                            <span className="ml-6 min-w-0 flex flex-col">
                    <span className="text-sm font-semibold tracking-wide text-gray-500">{step.name}</span>
                  </span>
                                        </div>
                                    </>
                                )}
                            </li>
                        ))}
                    </ol>
                </nav>
            }
        </>
    )
}
