import React from "react";
import Tooltip from "../Tooltip";
import InputLabel from "./InputLabel";

type props = {
    label: string
    reference: string
    required: boolean
    options: any
    onChange?: any
    defaultValue?: string
    metaField?: boolean
    icon?: 'warning'
    tooltip?: string
}

export default function SelectInput({label, reference, options, onChange, defaultValue, icon, tooltip }: props): React.ReactElement {
    return (
        <div>
            <InputLabel reference={reference} tooltip={tooltip} icon={icon} label={label} />
            <div className="mt-1 relative">
                <select
                    onChange={onChange}
                    id={reference}
                    defaultValue={defaultValue}
                    name={reference}
                    className={`appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm`}
                >
                    {options.map((option: any) => (
                        <option key={option.value} value={option.value}>{option.label}</option>
                    ))}
                </select>
            </div>
        </div>
    )
}
