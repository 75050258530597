import useSWR from "swr";
import axios from "../lib/axios";

const DataFetcher = (url, params) => axios.get(url, {
    params: params
}).then(res => res.data.data).finally(res => res)

function Data(url, params) {
    const { data, error } = useSWR([url, params], DataFetcher, {
        revalidateOnFocus: false,
        revalidateOnMount: true,
    })
    return {
        data: data,
        isLoading: !error && !data,
        isError: error
    }
}

export default Data
