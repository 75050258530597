import React, {useState} from "react";
import InputLabel from "./InputLabel";

type props = {
    label: string
    reference: string
    required: boolean
    onChange?: any
    value?: boolean
    icon?: 'warning'
    tooltip?: string
}

export default function BooleanInput({label, reference, onChange, tooltip, value, icon}: props): React.ReactElement {
    return (
        <div>
            <div className="flex mb-2">
                <InputLabel reference={reference} tooltip={tooltip} icon={icon} label={label} />
            </div>

            <label className="inline-flex relative items-center cursor-pointer">
                <input onChange={onChange} id={reference} name={reference} type="checkbox" value={reference} checked={!!value} className="sr-only peer"/>
                <div
                    className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-transparent dark:peer-focus:ring-green-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-green-600"></div>

            </label>
        </div>
    )
}
