// @ts-ignore
import {SpeakerphoneIcon} from '@heroicons/react/outline'
import {ExclamationCircleIcon} from "@heroicons/react/solid";
import {Link} from "react-router-dom";

type props = {
    shortText: string
    text: string
    buttonText: string
    buttonLink: string
    color: 'green' | 'blue'
    target?: '_blank'
}

export default function Banner({shortText, text, buttonText, buttonLink, color = 'green', target }: props): React.ReactElement {
    return (
        <div className={`p-2 bg-${color}-600 shadow-lg sm:p-3`}>
            <div className="max-w-8xl container mx-auto px-2 sm:px-6 lg:px-8">
                <div className="flex items-center justify-between flex-wrap">
                    <div className="md:w-0 flex-1 flex items-center">
              <span className={`flex p-2 bg-${color}-800`}>
                <ExclamationCircleIcon className="h-6 w-6 text-white" aria-hidden="true" />
              </span>
                        <p className="ml-3 font-medium text-white truncate">
                            <span className="md:hidden">{shortText}</span>
                            <span className="hidden md:inline">{text}</span>
                        </p>
                    </div>
                    <div className="order-3 mt-2 flex-shrink-0 w-full sm:order-2 sm:mt-0 sm:w-auto">
                        <a
                            target={target}
                            href={buttonLink}
                            className={`flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-${color}-600 bg-white hover:bg-blue-50`}
                        >
                            {buttonText}
                        </a>
                    </div>
                </div>
            </div>

        </div>

    )
}
