import {HomeIcon} from "@heroicons/react/outline";
import Button from "../../atoms/Button";

import Data from "../../../utils/Data";
import Loader from "../../atoms/Loader";
import toast from "react-hot-toast";
import {BuildingMapper} from "../../../mappers/Building";
import Banner from "../Banner";
import React from "react";

export default function MyHouseCard(): React.ReactElement {
    const {data, isLoading, isError} = Data('/me/building')
    const {data: metaData} = Data('/me/building/meta')

    const building = BuildingMapper(data)

    // Exclude fields for calculation
    const excludeFields = (key: string) => {
        if(key === 'addition') return false;
        if(key === 'classification') return false;
        if(key === 'id') return false;
        if(key === 'external_bag_id') return false;
        if(key === 'has_hoa_permission') return false;
        if(key === 'hoa_poc_email') return false;
        if(key === 'hoa_poc_name') return false;
        if(key === 'hoa_poc_phone') return false;
        if(key === 'is_hoa_member') return false;
        if(key === 'is_owned_property') return false;
        return true;
    }

    const calculatePercentage = () => {
        let empty = 0;
        if(metaData) {
            Object.entries(metaData).forEach(([key, value], index) => { // @ts-ignore
                if(value.source === 'CALCULATED') empty++ });
            Object.entries(building).forEach(([key, value], index) => { if(value == null && excludeFields(key)) empty++ });
            return Math.round(100 - (100/(Object.values(building).length+metaData.length) * (empty === -4 ? 0 : empty)));
        }
        return 0;
    }

    if (isError) toast.error("Kan 'mijn woning' niet laden")

    function monthlyCosts() {
        if(building.gas_monthly_cost && building.electricity_monthly_cost) {
            return building.gas_monthly_cost  + building.electricity_monthly_cost;
        }
        if(building.gas_monthly_cost) {
            return building.gas_monthly_cost;
        }
        if(building.electricity_monthly_cost) {
            return building.electricity_monthly_cost;
        }
    }

    function energyUsage() {
        if(building.gas_usage || building.electricity_usage) return true;
        return false;
    }

    return (
        <div className="col-span-1 ">
            <div className="p-8 bg-white rounded-lg shadow divide-y divide-gray-200">
                {isLoading ? <Loader/> :
                    <div className="">
                        {/*{!energyUsage() && <Banner text="Om te berekenen wat je verwachte besparingen zullen zijn hebben we je maandelijkse energieverbruik nodig. Deze gegevens heb je nog niet ingevuld." shortText="Je woningprofiel is niet compleet" buttonText="Gegevens aanvullen" buttonLink="/mijn-woning#energieverbruik"/> }*/}
                        <div>
                            <div className="flex items-center justify-between mb-6">
                                <div className="inline-flex items-center">
                            <span className="mr-4 rounded-lg inline-flex p-3 bg-emerald-100 text-teal-700 ring-4 ring-white">
                                <HomeIcon className="h-6 w-6 text-emerald-600" aria-hidden="true" />
                            </span>
                                    <h2 className="text-xxl font-bold tracking-tight text-gray-900 md:text-2xl">Mijn woning</h2>
                                </div>
                                <Button to="/mijn-woning" icon="edit" text="Gegevens wijzigen"/>
                            </div>
                            <ul className="my-4 pt-4 block text-lg text-gray-900 leading-snugger">
                                <li>{ building.street} {building.number} {building.addition}</li>
                                <li>{building.zipcode} {building.city}</li>
                            </ul>
                            <div className="grid grid-cols-1 text-sm gap-6 sm:grid-cols-2 lg:grid-cols-2">
                                <div className="col-span-1 mt-4">

                                    <h3 className="text-lg font-bold mb-4 tracking-tight text-gray-900 md:text-xl">Algemene gegevens</h3>
                                    <div className="mt-2 sm:grid sm:grid-cols-2">
                                        <dt className="text-sm font-medium text-gray-500">
                                            Oppervlakte
                                        </dt>
                                        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-1">
                                            {building.surface}m2
                                        </dd>
                                    </div>
                                    <div className="mt-2 sm:grid sm:grid-cols-2">
                                        <dt className="text-sm font-medium text-gray-500">
                                            Bouwjaar
                                        </dt>
                                        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-1">
                                            {building.year}
                                        </dd>
                                    </div>
                                    <div className="mt-2 sm:grid sm:grid-cols-2">
                                        <dt className="text-sm font-medium text-gray-500">
                                            Type
                                        </dt>
                                        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-1">
                                            {building.type}
                                        </dd>
                                    </div>
                                </div>
                                {monthlyCosts() &&
                                    <div className="col-span-1 mt-4">
                                        <h3 className="text-lg mb-4 font-bold tracking-tight text-gray-900 md:text-xl">Energieverbruik</h3>
                                        {(building.gas_usage !== null && building.gas_usage !== 0) &&
                                            <div className="mt-2 sm:grid sm:grid-cols-2">
                                                <dt className="text-sm font-medium text-gray-500">
                                                    Gasverbruik
                                                </dt>
                                                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-1">
                                                    {building.gas_usage}m3 per jaar
                                                </dd>
                                            </div>
                                        }
                                        {(building.electricity_usage !== null && building.electricity_usage !== 0) &&
                                            <div className="mt-2 sm:grid sm:grid-cols-2">
                                                <dt className="text-sm font-medium text-gray-500">
                                                    Stroomverbruik
                                                </dt>
                                                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-1">
                                                    {building.electricity_usage}kWh per jaar
                                                </dd>
                                            </div>
                                        }
                                        <div className="mt-2 mb-4 sm:grid sm:grid-cols-2">
                                            <dt className="text-sm font-medium text-gray-500">
                                                Maandlasten
                                            </dt>
                                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-1">
                                                €{monthlyCosts()}
                                            </dd>
                                        </div>
                                    </div>
                                }

                            </div>
                        </div>

                        <div className="h-8 w-full mt-10 relative w-full rounded-lg overflow-hidden">
                            <div className="w-full h-full bg-gray-100 absolute"></div>
                            <div id="bar" style={{ width: calculatePercentage()+'%'}} className="transition-all ease-out duration-1000 flex items-center justify-center h-full bg-green-600 text-center text-white text-sm relative">
                                <span className="font-semibold">{calculatePercentage()}</span>% ingevuld</div>
                        </div>
                    </div>
                }
            </div>


        </div>
    )
}
