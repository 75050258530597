import React from "react";
import Breadcrumbs from "../../components/organisms/Breadcrumbs";
import { Disclosure } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/outline'
import ContentCard from "../../components/molecules/ContentCard";

export default function Faq(): React.ReactElement {

    const aboutFaqs = [
        {
            question: "Wat is Bureau voor Verduurzamen?",
            answer:
                "Bureau voor Verduurzamen is hét adres waar je terecht kan voor onafhankelijk advies over het verduurzamen van de woning. Naast het adviseren zorgen wij ook dat de opdracht uitgevoerd wordt. Hiervoor werken wij samen met vele partners verspreid over heel Nederland. In het kort: alles op een adres!",
        },
        {
            question: "Hoe wordt beslist wie de opdracht uitvoert?",
            answer:
                "Wij zijn onafhankelijk van de uitvoerders en werken samen met verschillende partners door heel Nederland. Bij Bureau voor Verduurzamen zijn alle partners geselecteerd op basis van een aantal criteria. Zo werken wij alleen met partners die gecertificeerd zijn en voldoen aan onze eigen kwaliteitseisen. Hierdoor bespaar jij veel tijd en hoef je niet alles zelf uit te zoeken. Indien je zelf voorkeur hebt voor een partner, of vragen hebt over een van onze partners, dan kun je dit uiteraard kenbaar maken bij onze adviseur.",
        },
        {
            question: "Zijn er ook kosten verbonden aan het advies?",
            answer:
                "Bij Bureau voor Verduurzamen betaal je geen kosten voor het standaard telefonisch advies. Het kost je niks. Dit kunnen wij waarmaken door de samenwerking met onze partners. Je betaalt alleen voor de uitvoering van de maatregel. De prijs hiervan krijg je in de offerte van de adviseur. Hierin zijn alle kostenposten in detail verwerkt.",
        },
        {
            question: "Waarom Bureau voor Verduurzamen?",
            answer:
                "Natuurlijk zijn er veel verschillende bedrijven waar je je kunt laten adviseren. Daarom krijgen wij vaak de vraag: waarom juist bij Bureau voor Verduurzamen? Het antwoord is simpel: je profiteert bij ons van onafhankelijke advies en service in de uitvoering.\n\nIn het kort profiteer je bij Bureau voor Verduurzamen omdat:\n" +
                "\n" +
                "Uitvoerders besparen op hun kosten door de samenwerking met ons;\n" +
                "Wij gebruik maken van online systemen om effectiever en efficiënter te werk te gaan;\n" +
                "Met onze partners inkoopvoordelen hebben en de beste prijs/kwaliteit verhouding bieden.",
        },
        {
            question: "Van wie ontvang ik de offertes?",
            answer:
                "Bij Bureau voor Verduurzamen vergelijken we alle materialen die geschikt kunnen zijn voor jouw woonsituatie. Op basis van dat advies stelt de adviseur een vrijblijvende offerte op, zodat je een goede indicatie hebt van de investering en besparing. Na de inspectie ontvang je de definitieve offerte van ons. Deze offerte is in samenwerking met de installateur opgesteld.",
        },
    ]

    const generalFaqs = [
        {
            question: "Hoe ziet het adviesgesprek eruit?",
            answer:
                "Zodra je een aanvraag doet voor een adviesgesprek nemen wij contact op om een telefonische afspraak te maken met een van onze adviseurs. Deze bespreekt de situatie en bekijkt samen met jou de mogelijkheden voor het verduurzamen van de woning. Na het telefonisch adviesgesprek weet je welke mogelijkheden er zijn voor de gekozen maatregelen en ontvang je direct één (of meerdere) vrijblijvende offertes.",
        },
        {
            question: "Hoe ziet een offerte eruit?",
            answer:
                "De offerte is een formele aanbieding van de werkzaamheden waarop de belangrijkste aangeboden producten/diensten worden opgesomd. Bij Bureau voor Verduurzamen worden offertes meestal digitaal gepresenteerd. Offertes ontvangt u in dat geval per e-mail.",
        },
        {
            question: "Hoe geef je akkoord op de offerte?",
            answer:
                "Je kunt de opdracht geven door de ontvangen offerte te ondertekenen. De offerte ontvang je per e-mail. Het ondertekenen kan digitaal en per post. Zodra je de opdracht hebt gegeven, ontvang je van ons een opdrachtbevestiging. Wij zullen ervoor zorgen dat administratie en uitvoering gecoördineerd wordt met de partner.",
        },
        {
            question: "Hoe ziet een technische inspectie eruit?",
            answer: "Bij een technische inspectie komt de uitvoerders langs bij de woning. Dit is een laatste controle waarin wordt beoordeeld of de maatregel wel of niet kan worden uitgevoerd. Op deze manier kan hij een aantal bouwkundige aspecten van de woning bekijken. De adviseur zal de woning onderzoeken:\n" +
                "\n" +
                "Bij spouwmuurisolatie: de adviseur boort een gaatje in de buitenmuur en bekijkt met een endoscoop of de spouwmuur geschikt is voor isolatie. Tevens worden andere elementen van de spouw en gevel bekeken.\n" +
                "\n" +
                "Bij vloerisolatie: de adviseur kijkt onder de vloer om te bekijken of er ruimte is en of de vloer goed toegankelijk is. Tevens worden andere elementen van de kruipruimte bekeken bijvoorbeeld of er veel puin ligt.\n" +
                "\n" +
                "Na de inspectie van de woning zal de inspecteur eventuele aanpassingen doorvoeren. Bij een akkoord na de technische controle verandert de voorlopige offerte in de definitieve offerte.",
        },
        {
            question: "Wat moet je voorbereiden voor een inspecte?",
            answer:
                "Welke voorbereiding je moet treffen, is afhankelijk van de isolatiemaatregel en het materiaal. Voor de uitvoerdatum wordt gecommuniceerd wat de installateurs nodig zijn. Zo hebben onze partners ruimte nodig voor het plaatsen van het materiaal voor het uitvoeren van de maatregel. Is dit van te voren geregeld dan kunnen ze snel beginnen met de uitvoering van de opdracht!\n" +
                "\n",
        },
        {
            question: "Wat gebeurt er na akkoord van de opdracht?",
            answer: "Nadat je de opdracht hebt gegeven zal de desbetreffende partner contact met je opnemen om een afspraak te maken voor het uitvoeren van de maatregel. Samen prik je een datum voor het uitvoeren en dan hoef je alleen nog maar de dag af te wachten.",
        },
        {
            question: "Hoelang duurt de uitvoering?",
            answer: "De uitvoering van één duurzame maatregel is in de meeste gevallen al binnen een dagdeel uitgevoerd."
        },
        {
            question: "Krijg ik garantie op de uitvoering?",
            answer: "Vanuit onze partners krijg je standaard 10 jaar garantie op de producten en op de uitvoering. Deze garantie is overdraagbaar naar een eventuele nieuwe eigenaar van de woning."
        }
    ]

    function classNames(...classes: any) {
        return classes.filter(Boolean).join(' ')
    }
    const links = [
        { name: 'Veelgestelde vragen', to: '/veelgestelde-vragen' },
    ]
    return (
        <div className="px-4 container mx-auto">


            <div className="max-w-4xl mx-auto">
                <Breadcrumbs links={links} />
                <div className="col-span-1 p-8 bg-white rounded-lg shadow mb-12">
                    <div className="divide-y-2 divide-gray-200">
                        <h2 className="text-left text-2xl font-extrabold text-gray-900 sm:text-3xl">Algemene vragen</h2>
                        <dl className="mt-6 space-y-6 divide-y divide-gray-200">
                            {generalFaqs.map((faq) => (
                                <Disclosure as="div" key={faq.question} className="pt-6">
                                    {({ open }) => (
                                        <>
                                            <dt className="text-lg">
                                                <Disclosure.Button className="text-left w-full flex justify-between items-start text-gray-400">
                                                    <span className="font-medium text-lg text-gray-900">{faq.question}</span>
                                                    <span className="ml-6 h-7 flex items-center">
                          <ChevronDownIcon
                              className={classNames(open ? '-rotate-180' : 'rotate-0', 'h-6 w-6 transform')}
                              aria-hidden="true"
                          />
                        </span>
                                                </Disclosure.Button>
                                            </dt>
                                            <Disclosure.Panel as="dd" className="mt-2 pr-12">
                                                <p className="text-lg font-normal text-gray-600 whitespace-pre-wrap">{faq.answer}</p>
                                            </Disclosure.Panel>
                                        </>
                                    )}
                                </Disclosure>
                            ))}
                        </dl>
                    </div>
                </div>
                <div className="col-span-1 p-8 bg-white rounded-lg shadow mb-12">
                    <div className="divide-y-2 divide-gray-200">
                        <h2 className="text-left text-2xl font-extrabold text-gray-900 sm:text-3xl">Over Bureau voor Verduurzamen</h2>
                        <dl className="mt-6 space-y-6 divide-y divide-gray-200">
                            {aboutFaqs.map((faq) => (
                                <Disclosure as="div" key={faq.question} className="pt-6">
                                    {({ open }) => (
                                        <>
                                            <dt className="text-lg">
                                                <Disclosure.Button className="text-left w-full flex justify-between items-start text-gray-400">
                                                    <span className="font-medium text-lg text-gray-900">{faq.question}</span>
                                                    <span className="ml-6 h-7 flex items-center">
                          <ChevronDownIcon
                              className={classNames(open ? '-rotate-180' : 'rotate-0', 'h-6 w-6 transform')}
                              aria-hidden="true"
                          />
                        </span>
                                                </Disclosure.Button>
                                            </dt>
                                            <Disclosure.Panel as="dd" className="mt-2 pr-12">
                                                <p className="text-lg font-normal text-gray-600 whitespace-pre-wrap">{faq.answer}</p>
                                            </Disclosure.Panel>
                                        </>
                                    )}
                                </Disclosure>
                            ))}
                        </dl>
                    </div>
                </div>


                <div className="mb-20 grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-2">
                    <ContentCard linkText="Lening aanvragen" linkTarget="__blank" linkUrl="mailto:advies@bureauverduurzamen.nl" title="Neem contact op" description="Er zijn verschillende aantrekkelijke financieringsmogelijkheden beschikbaar voor mensen die willen isoleren, een warmtepomp willen aanschaffen of zonnepanelen willen laten installeren. Vaak is dit tegen extra gunstige voorwaarden. Voor meer informatie kunt u navraag doen bij onze adviseurs."/>
                    <ContentCard linkText="Subsidie aanvragen" linkTarget="__blank" linkUrl="https://bureauverduurzamen.nl/subsidie" title="ISDE subsidie" description="Iedere woningeigenaar die voor grote isolatiemaatregelen kiest komt in aanmerking voor deze isolatiesubsidie. Met de ISDE-subsidie kun je tot wel 30 procent van de totale investering terugkrijgen.
Ook is het mogelijk om één isolatiemaatregel te combineren met een warmtepomp, zonneboiler of aansluiting op een warmtenet. Vanaf twee maatregelen verdubbelen de subsidiebedragen!"/>
                </div>
            </div>
        </div>
    )
}
