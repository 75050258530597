export function ConvertBuildingType(type: any) {
    switch (type) {
        case 'TERRACED_HOUSE':
            return 'Tussenwoning'
        case 'DETACHED_HOUSE':
            return 'Vrijstaande woning'
        case 'SEMI_DETACHED_HOUSE':
            return 'Geschakelde woning / 2 onder 1 kap'
        case 'CORNER_HOUSE':
            return 'Hoekwoning'
        case 'APARTMENT':
            return 'Appartement'
        case 'BUNGALOW':
            return 'Bungalow'
        case 'GARAGE':
            return 'Garage'
        case 'OTHER':
            return 'Overig'
        default:
            return 'Woning'
    }
}
